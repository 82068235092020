<template>
  <svg :width="size" :height="size" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.625 2.5H15V6.25H7.5V2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V18.125C0 19.1602 0.839844 20 1.875 20H20.625C21.6602 20 22.5 19.1602 22.5 18.125V4.375C22.5 3.33984 21.6602 2.5 20.625 2.5ZM11.25 8.75C12.6289 8.75 13.75 9.87109 13.75 11.25C13.75 12.6289 12.6289 13.75 11.25 13.75C9.87109 13.75 8.75 12.6289 8.75 11.25C8.75 9.87109 9.87109 8.75 11.25 8.75ZM14.8945 17.5H7.60547C7.19922 17.5 6.87109 17.1094 6.99609 16.7266C7.32031 15.7266 8.26172 15 9.375 15H9.69531C10.1758 15.1992 10.6992 15.3125 11.25 15.3125C11.8008 15.3125 12.3281 15.1992 12.8047 15H13.125C14.2344 15 15.1758 15.7227 15.5039 16.7266C15.6289 17.1094 15.3008 17.5 14.8945 17.5ZM13.75 1.25C13.75 0.558594 13.1914 0 12.5 0H10C9.30859 0 8.75 0.558594 8.75 1.25V5H13.75V1.25Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IdCardIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
