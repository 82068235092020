import moment from 'moment';
import router from '@/router';

const store = {
  namespaced: true,
  state: {
    user: {},
  },
  mutations: {
    UPDATE_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      payload.hiringDate = moment(payload.hiringDate).format('YYYY-MM-DD');
      state.user = payload.person;
    },
  },
  actions: {
    updateUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },
    logout(context) {
      localStorage.removeItem('@GSCMX-PC:access_token');
      localStorage.removeItem('@GSCMX-PC:refresh_token');
      context.commit('UPDATE_USER', {});
      router.push('/login');
    },
  },
};

export default store;
