<template>
  <div v-if="grouped">
    <div
      class="calendar-day calendar-round"
      :class="{
        past: past,
        future: future,
        active: active,
      }"
    >
      <template v-for="(item, index) in data">
        <div
          :key="index"
          class="calendar-day calendar-round"
          v-if="data.length == 5 ? index < 5 : index < 4"
        >
          <div class="calendar-day-tooltip">
            <div class="flex fw-w fgap2">
              <div>
                <p v-if="item.identification">Ref. Prime:</p>
                <p v-if="item.identification" class="mb-1">
                  <span class="text-bold">
                    {{ item.typeOfProcess | typeOfProcess }}
                    {{ String(item.identification).padStart(5, '0') }}
                  </span>
                </p>
              </div>

              <div>
                <p v-if="item.customerRef">Ref. Cliente:</p>
                <p v-if="item.customerRef" class="mb-1">
                  <span class="text-bold">{{ item.customerRef }}</span>
                </p>
              </div>

              <div>
                <p v-if="item.invoice">Invoice:</p>
                <p v-if="item.invoice" class="mb-1">
                  <span class="text-bold">{{ item.invoice }}</span>
                </p>
              </div>

              <div>
                <p v-if="item.exporter">Exportador:</p>
                <p v-if="item.exporter" class="mb-1">
                  <span class="text-bold">{{ item.exporter }}</span>
                </p>
              </div>

              <div>
                <p v-if="item.manufacturer">Fabricante:</p>
                <p v-if="item.manufacturer">
                  <span class="text-bold">{{ item.manufacturer }}</span>
                </p>
              </div>
            </div>
          </div>

          <div
            class="calendar-day-content"
            :class="{
              eta: item.itemType === 'datesETA',
              etd: item.itemType === 'datesETD',
              chegada: item.itemType === 'datesArrivalDate',
              embarque: item.itemType === 'datesDepartureDate',
            }"
          >
            <div class="flex1 flex ai-c fgap0.5 jc-sb" @click="$emit('innerAction', item)">
              <span class="material-symbols-outlined" style="font-size: 18px;" v-if="item.itemType == 'datesArrivalDate' || item.itemType == 'datesDepartureDate'">
                bookmark_check
              </span>
              <p class="customer-ref flex1">
                <span v-if="useField == 'identification'">
                  <span class="bold">{{ getCalendarTypeShort(item) }}</span>:
                  {{ item.typeOfProcess | typeOfProcess }}{{ item[useField] ?? 'S/I' }}
                </span>
                <span v-else>
                  <span class="bold">{{ getCalendarTypeShort(item) }}</span>:
                  {{ item[useField] ?? 'S/I' }}
                </span>
              </p>
              <span class="material-symbols-outlined" style="font-size: 18px;" v-if="item.itemType == 'datesETA' || item.itemType == 'datesETD'">
                alarm
              </span>
            </div>
          </div>
        </div>
      </template>

      <div class="calendar-day-footer" v-if="data.length > 4">
        <button @click.prevent="$emit('action', data)">
          <span>VER MAIS ITENS</span>
          <br />
          <span>{{ data.length - 4 }} itens ocultos</span>
        </button>
      </div>
    </div>
  </div>

  <div class="calendar-list-day" v-else>
    <template v-for="(item, index) in data">
      <div :key="index" class="calendar-day calendar-round">
        <div class="calendar-day-tooltip">
          <div class="flex fw-w fgap2">
            <div>
              <p v-if="item.identification">Ref. Prime:</p>
              <p v-if="item.identification" class="mb-1">
                <span class="text-bold">
                  {{ item.typeOfProcess | typeOfProcess }}
                  {{ String(item.identification).padStart(5, '0') }}
                </span>
              </p>
            </div>

            <div>
              <p v-if="item.customerRef">Ref. Cliente:</p>
              <p v-if="item.customerRef" class="mb-1">
                <span class="text-bold">{{ item.customerRef }}</span>
              </p>
            </div>

            <div>
              <p v-if="item.invoice">Invoice:</p>
              <p v-if="item.invoice" class="mb-1">
                <span class="text-bold">{{ item.invoice }}</span>
              </p>
            </div>

            <div>
              <p v-if="item.exporter">Exportador:</p>
              <p v-if="item.exporter" class="mb-1">
                <span class="text-bold">{{ item.exporter }}</span>
              </p>
            </div>

            <div>
              <p v-if="item.manufacturer">Fabricante:</p>
              <p v-if="item.manufacturer">
                <span class="text-bold">{{ item.manufacturer }}</span>
              </p>
            </div>
          </div>
        </div>

        <div
          class="calendar-day-content"
          :class="{
            eta: item.itemType === 'datesETA',
            etd: item.itemType === 'datesETD',
            chegada: item.itemType === 'datesArrivalDate',
            embarque: item.itemType === 'datesDepartureDate',
          }"
        >
          <div class="flex1 flex ai-c fgap0.5 jc-sb" @click="$emit('innerAction', item)">
            <span class="material-symbols-outlined" style="font-size: 18px;" v-if="item.itemType == 'datesArrivalDate' || item.itemType == 'datesDepartureDate'">
              bookmark_check
            </span>
            <p class="customer-ref flex1">
              {{ getCalendarTypeShort(item) }}: <span class="bold">{{ item.invoice ?? 'S/I' }}</span>
            </p>
            <span class="material-symbols-outlined" style="font-size: 18px;" v-if="item.itemType == 'datesETA' || item.itemType == 'datesETD'">
              alarm
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GsCalendarDayContent',
  props: {
    date: {
      type: Date,
    },
    data: {
      type: Array,
      default: () => [],
    },
    past: {
      type: Boolean,
      default: false,
    },
    future: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: true,
    },
    useField: {
      type: String,
      default: 'invoice',
    },
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
    fractionFilter(value) {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    typeOfProcess(value) {
      switch (value) {
        case 'CONTA_E_ORDEM':
          return 'PRI';

        case 'ENCOMENDA':
          return 'E';

        case 'ACESSORIA':
          return 'A';

        default:
          return 'PRI';
      }
    },
  },
  methods: {
    getCalendarType(item) {
      const types = {
        datesETA: 'ETA',
        datesETD: 'ETD',
        datesArrivalDate: 'Chegada',
        datesDepartureDate: 'Embarque',
      };

      return types[item.itemType];
    },
    getCalendarTypeShort(item) {
      const types = {
        datesETA: 'ETA',
        datesETD: 'ETD',
        datesArrivalDate: 'CGD',
        datesDepartureDate: 'EMB',
      };

      return types[item.itemType];
    },
    getInvoiceCurrency(item, invoiceId) {
      return item.invoices.find((inv) => inv.id === invoiceId).currency;
    },
    itemTotal(item, date) {
      let total = 0;
      let totalOtherCoin = 0;

      if (item.itemType === 'estimatedStepCashRequest') {
        const estimatedStepCashRequestDate = item.estimatedStepCashRequest.split('T')[0];
        const itemDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        if (estimatedStepCashRequestDate === itemDate) {
          total += item.estimatedStepCashRequestEstimatedValue;
        }
      }

      if (item.itemType === 'datesETA') {
        const stepCashRequestDate = item.datesETA.split('T')[0];
        const itemDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1,
        ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        if (stepCashRequestDate === itemDate) {
          if (!item.stepCashRequestEstimatedValue) {
            total += item.estimatedStepCashRequestEstimatedValue;
          } else {
            total += item.stepCashRequestEstimatedValue;
          }
        }
      }

      if (item.itemType === 'invoice') {
        for (let i = 0; i < item.invoices.length; i += 1) {
          const invoice = item.invoices[i];

          if (item.itemInvoice === invoice.id) {
            if (invoice.installments && invoice.installments.length > 0) {
              for (let j = 0; j < invoice.installments.length; j += 1) {
                const parcel = invoice.installments[j];
                const itemDate = `${date.getFullYear()}-${String(
                  date.getMonth() + 1,
                ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                if (parcel.dueDate.split('T')[0] === itemDate) {
                  total += parcel.valueInBrl;
                  totalOtherCoin += parcel.value;
                }
              }
            }
          }
        }
      }

      return {
        total,
        totalOtherCoin,
      };
    },
  },
};
</script>

<style scoped>
.calendar-day {
  cursor: pointer;
  /* overflow: hidden; */
  margin-bottom: 0.25rem;
  position: relative;
}

.calendar-day-tooltip {
  position: absolute;
  left: 0;
  bottom: calc(100% + 5px);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  z-index: 999999;
  pointer-events: none;

  transition: all 0.1s ease-in-out 0s;

  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 15px, 0);
}

.calendar-day:hover > .calendar-day-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.calendar-day-content {
  padding: 3px 0.5rem;
  font-size: 0.8rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.calendar-day-content p {
  font-size: 0.75rem;
  font-weight: 500;
}

.calendar-day-content,
.calendar-day-content p {
  transition: all 0.2s ease-in-out 0s;
}

/* ETA */

.calendar-day-content.eta {
  background-color: #147702;
  border-color: #147702;
}

.calendar-day-content.eta span,
.calendar-day-content.eta p {
  color: white;
}

.calendar-day-content.eta:hover {
  background-color: white;
}

.calendar-day-content.eta:hover span,
.calendar-day-content.eta:hover p {
  color: #147702;
}

/* ETA */

/* Chegada */

.calendar-day-content.chegada {
  background-color: #083501;
  border-color: #083501;
}

.calendar-day-content.chegada span,
.calendar-day-content.chegada p {
  color: white;
}

.calendar-day-content.chegada:hover {
  background-color: white;
}

.calendar-day-content.chegada:hover span,
.calendar-day-content.chegada:hover p {
  color: #083501;
}

/* Chegada */

/* ETD */

.calendar-day-content.etd {
  background-color: #1866b4;
  border-color: #1866b4;
}

.calendar-day-content.etd span,
.calendar-day-content.etd p {
  color: white;
}

.calendar-day-content.etd:hover {
  background-color: white;
}

.calendar-day-content.etd:hover span,
.calendar-day-content.etd:hover p {
  color: #1866b4;
}

/* ETD */

/* Embarque */

.calendar-day-content.embarque {
  background-color: #104375;
  border-color: #104375;
}

.calendar-day-content.embarque span,
.calendar-day-content.embarque p {
  color: white;
}

.calendar-day-content.embarque:hover {
  background-color: white;
}

.calendar-day-content.embarque:hover span,
.calendar-day-content.embarque:hover p {
  color: #09233c;
}

/* Embarque */

.calendar-list-day .calendar-day {
  cursor: pointer;
}

.calendar-day-header {
  padding: 0.5rem;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: var(--primary-color);
  text-transform: uppercase;
}

.calendar-list-day .calendar-day-header {
  margin-bottom: 0;
}

.calendar-day-header.past {
  background-color: #2b6a17;
}

.calendar-day-header.future {
  background-color: var(--primary-color);
}

.calendar-day-header.active {
  background-color: #e2665e;
}

.calendar-day-footer {
  /* padding: 0.75rem; */
  font-size: 0.9rem;
}

.calendar-day-footer {
  padding-top: 0;
}

.calendar-day-footer button {
  font-family: var(--body-font-family);
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 10px;
  font-weight: 900;
  border: 0;
  background-color: transparent;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  margin-top: 0.5rem;
}

.calendar-day-footer button span:last-of-type {
  font-weight: 300;
}

.calendar-list-day {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.customer-ref {
  word-break: break-all;
}

.gs-download {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--accent-color);
  color: white;
}

.gs-download.downloaded {
  background-color: #e2e2e2;
  color: black;
}

.button-tooltip {
  transition: all 0.2s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 9;
  bottom: 50%;
  transform: translate3d(0, 50%, 0);
  right: calc(100% + 10px);
  background-color: white;
  color: black;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 0.5rem;
  border: 1px solid black;
}

.gs-download:hover ~ .button-tooltip {
  opacity: 1;
  visibility: visible;
}

.calendar-list-day .calendar-day:first-child .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(2) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(3) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(4) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(5) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(6) .calendar-day-tooltip,
.calendar-list-day .calendar-day:nth-child(7) .calendar-day-tooltip {
  top: calc(100% + 5px);
  bottom: unset;
}
</style>
