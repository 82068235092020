<template>
  <div>
    <slot
      name="header"
      :data="{
        week,
        year,
        month,
        months,
        nextMonth,
        prevMonth,
        nextWeek,
        prevWeek,
        calendarItems,
        weekData
      }"
    ></slot>

    <div class="calendar-body">
      <ul class="calendar-weekdays flex ai-c">
        <li class="pv-1 text-medium text-center">Domingo</li>
        <li class="pv-1 text-medium text-center">Segunda</li>
        <li class="pv-1 text-medium text-center">Terça</li>
        <li class="pv-1 text-medium text-center">Quarta</li>
        <li class="pv-1 text-medium text-center">Quinta</li>
        <li class="pv-1 text-medium text-center">Sexta</li>
        <li class="pv-1 text-medium text-center">Sábado</li>
        <li class="pv-1 text-bold text-center text-accent">Previsão Semanal</li>
      </ul>

      <ul class="calendar-dates relative flex fw-w">
        <GsCalendarItem
          v-for="(item, index) in calendarItems"
          :key="index"
          :day="item.day"
          :active="item.active"
          :other-month="item.otherMonth"
          :past="item.past"
          :future="item.future"
          :long="week"
          :overview="!!item.overview"
          :content="item.content"
        >
          <slot
            v-if="$scopedSlots.item && item.content && !item.overview"
            name="item"
            v-bind="item"
          />
          <slot
            v-if="$scopedSlots.item && !!item.overview"
            name="week"
            v-bind="item"
          />
        </GsCalendarItem>

        <template v-if="week">
          <div
            class="calendar-item-footer"
            v-for="(item, index) in calendarItems"
            :key="(index + 1) * 10"
          >
            <slot name="itemFooter" v-bind="item"/>
          </div>
        </template>

        <slot name="overlay"/>
      </ul>
    </div>

    <slot
      name="footer"
      :data="{
        week,
        year,
        month,
        months,
        nextMonth,
        prevMonth,
        nextWeek,
        prevWeek,
        calendarItems,
        weekData
      }"
    ></slot>
  </div>
</template>

<script>
import GsCalendarItem from '@/components/GsCalendarItem.vue';

export default {
  name: 'GsCalendar',
  components: { GsCalendarItem },
  props: {
    content: {
      required: true,
    },
    week: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendarItems: [],
      weekData: {
        start: null,
        end: null,
      },
      date: null,
      year: null,
      month: null,
      currentDate: null,
      calendarContent: '',
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    };
  },
  methods: {
    makeCalendar() {
      this.calendarItems = [];
      const dayone = new Date(this.year, this.month, 1).getDay();
      const lastdate = new Date(this.year, this.month + 1, 0).getDate();
      const dayend = new Date(this.year, this.month, lastdate).getDay();
      const monthlastdate = new Date(this.year, this.month, 0).getDate();

      for (let i = dayone; i > 0; i -= 1) {
        this.calendarItems.push({
          index: `past-${i}`,
          past: this.getIsPast(i, new Date(this.year, this.month, 0).getMonth(), new Date(this.year, this.month, 0).getFullYear()),
          future: false,
          day: monthlastdate - i + 1,
          content: null,
          active: false,
          otherMonth: true,
        });
      }

      for (let i = 1; i <= lastdate; i += 1) {
        const isToday = !!(i === this.date.getDate()
          && this.month === new Date().getMonth()
          && this.year === new Date().getFullYear());

        const dd = new Date(this.year, this.month, i);

        const { year } = this;
        const month = String(this.month + 1).padStart(2, '0');
        const day = String(i).padStart(2, '0');

        this.calendarItems.push({
          index: `day-${i}`,
          past: this.getIsPast(i, this.month, this.year),
          future: i > this.date.getDate(),
          day: i,
          active: isToday,
          otherMonth: this.month !== new Date().getMonth(),
          content: this.content[`${year}-${month}-${day}`] ? this.content[`${year}-${month}-${day}`] : null,
          date: new Date(this.year, this.month, i),
        });

        if (dd.getDay() === 6) {
          const firstDayOfWeekDate = new Date(dd.setDate(dd.getDate() - dd.getDay()));
          const lastDayOfWeekDate = new Date(dd.setDate(dd.getDate() - dd.getDay() + 6));

          let fullContent = null;

          if (firstDayOfWeekDate.getMonth() < lastDayOfWeekDate.getMonth()) {
            for (let j = lastDayOfWeekDate.getDate(); j >= 1; j -= 1) {
              if (this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]) {
                if (fullContent === null) fullContent = [];

                fullContent.push(...this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]);
              }
            }
          } else {
            for (let j = lastDayOfWeekDate.getDate(); j >= firstDayOfWeekDate.getDate(); j -= 1) {
              if (this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]) {
                if (fullContent === null) fullContent = [];

                fullContent.push(...this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]);
              }
            }
          }

          this.calendarItems.push({
            index: `week-${i}`,
            past: false,
            future: false,
            day: '',
            active: false,
            otherMonth: false,
            content: fullContent,
            overview: true,
          });
        }
      }

      for (let i = dayend; i < 6; i += 1) {
        this.calendarItems.push({
          index: `future-${i}`,
          past: this.getIsPast(i, new Date(this.year, this.month + 1, 1).getMonth(), new Date(this.year, this.month + 1, 1).getFullYear()),
          future: this.getIsFuture(i, new Date(this.year, this.month + 1, 1).getMonth(), new Date(this.year, this.month + 1, 1).getFullYear()),
          day: i - dayend + 1,
          active: false,
          otherMonth: true,
          content: null,
        });

        const dd = new Date(this.year, this.month + 1, i - dayend + 1);

        const { year } = this;
        const month = String(this.month + 1).padStart(2, '0');

        if (dd.getDay() === 6) {
          const firstDayOfWeekDate = new Date(dd.setDate(dd.getDate() - dd.getDay()));
          const lastDayOfWeekDate = new Date(dd.setDate(dd.getDate() - dd.getDay() + 6));

          let fullContent = null;

          if (firstDayOfWeekDate.getMonth() < lastDayOfWeekDate.getMonth()) {
            for (let j = firstDayOfWeekDate.getDate(); j <= lastdate; j += 1) {
              if (this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]) {
                if (fullContent === null) fullContent = [];

                fullContent.push(...this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]);
              }
            }
          } else {
            for (let j = lastDayOfWeekDate.getDate(); j >= firstDayOfWeekDate.getDate(); j -= 1) {
              if (this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]) {
                if (fullContent === null) fullContent = [];

                fullContent.push(...this.content[`${year}-${month}-${String(j).padStart(2, '0')}`]);
              }
            }
          }

          this.calendarItems.push({
            index: `wday-${i}`,
            past: false,
            future: false,
            day: '',
            active: false,
            otherMonth: false,
            content: fullContent,
            overview: true,
          });
        }
      }

      this.currentDate = `${this.months[this.month]} ${this.year}`;
    },
    nextMonth() {
      const next = this.month + 1;
      this.month = next;

      const lastDay = new Date(this.year, next + 1, 0).getDate();

      if (next > 11) {
        this.date = new Date(this.year + 1, 0, new Date().getDate());
        this.year += 1;
        this.month = this.date.getMonth();
      } else {
        this.date = new Date();
      }

      this.$emit('change', {
        year: this.year, month: this.month + 1, startDay: 1, endDay: lastDay,
      });
    },
    prevMonth() {
      const prev = this.month - 1;
      this.month = prev;

      const lastDay = new Date(this.year, prev + 1, 0).getDate();

      if (prev < 0) {
        this.date = new Date(this.year - 1, prev, new Date().getDate());
        this.year -= 1;
        this.month = this.date.getMonth();
      } else {
        this.date = new Date();
      }

      this.$emit('change', {
        year: this.year, month: this.month + 1, startDay: 1, endDay: lastDay,
      });
    },
    getWeekDate(start, index) {
      return new Date(start.setDate(start.getDate() + index));
    },
    makeCalendarWeek() {
      this.calendarItems = [];

      let fullContent = null;

      for (let i = 0; i < 7; i += 1) {
        const theDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
        const sundayDate = new Date(theDate.setDate(theDate.getDate() - theDate.getDay()));

        this.weekData = {
          start: new Date(theDate.setDate(theDate.getDate() - theDate.getDay())),
          end: new Date(theDate.setDate(theDate.getDate() - theDate.getDay() + 6)),
        };

        const date = this.getWeekDate(sundayDate, i);

        const isToday = !!(date.getDate() === new Date().getDate()
          && date.getMonth() === new Date().getMonth()
          && date.getFullYear() === new Date().getFullYear());

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        if (this.content) {
          this.calendarItems.push({
            index: `day-${i}`,
            past: date.getTime() < new Date().getTime(),
            future: date.getTime() > new Date().getTime(),
            day: date.getDate(),
            active: isToday,
            otherMonth: false,
            content: this.content[`${year}-${month}-${day}`] ? this.content[`${year}-${month}-${day}`] : null,
            date,
          });

          if (this.content[`${year}-${month}-${day}`]) {
            if (fullContent === null) fullContent = [];

            fullContent.push(...this.content[`${year}-${month}-${day}`]);
          }
        } else {
          this.calendarItems.push({
            past: date.getTime() < new Date().getTime(),
            future: date.getTime() < new Date().getTime(),
            day: date.getDate(),
            active: isToday,
            otherMonth: false,
            content: null,
          });
        }

        if (i === 6) {
          this.calendarItems.push({
            past: false,
            future: false,
            day: '',
            active: false,
            otherMonth: false,
            content: fullContent,
            overview: true,
          });
        }
      }
    },
    nextWeek() {
      this.date.setDate(this.date.getDate() + 7);
      this.$nextTick(() => {
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
      });

      this.$emit('changeWeek', {
        start: new Date(this.date.setDate(this.date.getDate() - this.date.getDay())),
        end: new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6)),
      });
    },
    prevWeek() {
      this.date.setDate(this.date.getDate() - 7);
      this.$nextTick(() => {
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
      });

      this.$emit('changeWeek', {
        start: new Date(this.date.setDate(this.date.getDate() - this.date.getDay())),
        end: new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6)),
      });
    },
    getIsPast(i, month, year) {
      let isPast = false;

      if (i < this.date.getDate() && month <= new Date().getMonth() && year <= new Date().getFullYear()) {
        isPast = true;
      }

      if (month < new Date().getMonth() && year <= new Date().getFullYear()) {
        isPast = true;
      }

      if (year < new Date().getFullYear()) {
        isPast = true;
      }

      return isPast;
    },
    getIsFuture(i, month, year) {
      let isFuture = false;

      if (i > this.date.getDate() && month >= new Date().getMonth() && year >= new Date().getFullYear()) {
        isFuture = true;
      }

      if (month > new Date().getMonth() && year >= new Date().getFullYear()) {
        isFuture = true;
      }

      if (year > new Date().getFullYear()) {
        isFuture = true;
      }

      return isFuture;
    },
  },
  mounted() {
    const newDate = new Date();
    this.date = newDate;
    this.year = newDate.getFullYear();
    this.month = newDate.getMonth();

    if (this.week) {
      this.makeCalendarWeek();
    } else {
      this.makeCalendar();
    }
  },
  watch: {
    content() {
      if (this.week) {
        this.makeCalendarWeek();
      } else {
        this.makeCalendar();
      }
    },
    week(newVal) {
      const newDate = new Date();
      this.date = newDate;
      this.year = newDate.getFullYear();
      this.month = newDate.getMonth();

      if (newVal) {
        this.makeCalendarWeek();

        this.$emit('changeWeek', {
          start: new Date(this.date.setDate(this.date.getDate() - this.date.getDay())),
          end: new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6)),
        });
      } else {
        this.makeCalendar();

        this.$emit('change', { year: this.year, month: this.month + 1 });
      }
    },
  },
};
</script>

<style scoped>
.calendar-weekdays {
  width: 100%;
  border-top: 1px solid #F4F7F9;
  border-bottom: 1px solid #F4F7F9;
}

.calendar-item-footer,
.calendar-weekdays li {
  flex: 0 0 12.5%;
}

.calendar-body,
.calendar-item-footer {
  overflow: hidden;
}

.calendar-dates {
  position: relative;
}
</style>
