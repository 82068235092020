import moment from 'moment';

export default function isFullMonth(firstDate, endDate) {
  const month = firstDate.format('M') - 1;

  const firstDateOfMonth = moment().month(month).startOf('month');
  const endDateOfMonth = moment().month(month).endOf('month');

  if (firstDate.format('MM') === endDate.format('MM') && firstDate.format('YYYY') === endDate.format('YYYY')) {
    return firstDate.format('DD') === firstDateOfMonth.format('DD') && endDate.format('DD') === endDateOfMonth.format('DD');
  }

  return false;
}
