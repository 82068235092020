<template>
  <div>
    <PageTitle
      pageTitle="Meus Dados"
      breadcrumbName="Meus Dados"
      class="mb-2"
    />
    <div class="card flex fd-c fgap2">
      <div>
        <h3 class="mb-1">MEUS DADOS</h3>
        <p class="text-light">Configurações dos seus dados</p>
      </div>

      <div class="columns">
        <form @submit.prevent="updateUserData" class="columns gap2 span-8 pr-3 user-form">
          <div class="span-6">
            <Input
              name="name"
              placeholder="Nome Completo"
              label="Nome Completo"
              className="text-bold"
              v-model="userData.name"
              required
            />
          </div>

          <div class="span-6">
            <Input
              name="email"
              placeholder="Email"
              label="Email"
              className="text-bold"
              v-model="userData.email"
              required
            />
          </div>

          <div class="span-12">
            <Toggle
              name="sit-siscarga"
              v-model="changePassword"
              :labels="{
                checked: 'Alterar Senha',
                unchecked: 'Alterar Senha'
              }"
            />
          </div>

          <transition name="pages">
            <div v-if="changePassword" class="span-6">
              <Input
                type="password"
                name="password"
                placeholder="Nova Senha"
                label="Nova Senha"
                className="text-bold"
                :hint="true"
                hintMessage="Sua senha deverá conter pelo menos um caractere especial ( ex.: !#$%ˆ&*()_? ) e pelo menos um dígito."
                v-model="userData.password"
              />
            </div>
          </transition>

          <transition name="pages">
            <div v-if="changePassword" class="span-6">
              <Input
                type="password"
                name="rePassword"
                placeholder="Repita a Nova Senha"
                label="Repita a Nova Senha"
                className="text-bold"
                :hint="true"
                hintMessage="Repita a senha anterior"
                v-model="userData.repeatedPassword"
              />
            </div>
          </transition>

          <!--          <div class="span-3">-->
          <!--            <Input-->
          <!--              type="date"-->
          <!--              name="birthOfDate"-->
          <!--              placeholder="Informe a sua data de Nascimento"-->
          <!--              label="Data de Nascimento"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.dateOfBirth"-->
          <!--              required-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-4">-->
          <!--            <Input-->
          <!--              type="date"-->
          <!--              name="hiringDate"-->
          <!--              placeholder="Informe a data em que você entrou na Empresa"-->
          <!--              label="Aniversário de Empresa"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.hiringDate"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-5">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="telephone1"-->
          <!--              placeholder="Informe o seu Telefone para contato"-->
          <!--              label="Telefone"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.telephone1"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-3 mt-1">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="jobRole"-->
          <!--              placeholder="Informe o seu cargo na Empresa"-->
          <!--              label="Cargo"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.jobRole"-->
          <!--              required-->
          <!--            />-->
          <!--          </div>-->

          <!--          <div class="span-3 mt-1">-->
          <!--            <label class="flex ai-c jc-sb">Setor</label>-->
          <!--            <select name="sector" id="sector" v-model="userData.sector">-->
          <!--              <option value="">Selecione um Setor</option>-->
          <!--              <option value="GERAL">Geral</option>-->
          <!--              <option value="VENDAS">Vendas</option>-->
          <!--              <option value="COMPRAS">Compras</option>-->
          <!--              <option value="EXPORTACAO">Exportação</option>-->
          <!--              <option value="FATURAMENTO">Faturamento</option>-->
          <!--              <option value="FINANCEIRO">Financeiro</option>-->
          <!--              <option value="FISCAL">Fiscal</option>-->
          <!--              <option value="IMPORTACAO">Importação</option>-->
          <!--              <option value="JURIDICO">Juridico</option>-->
          <!--              <option value="LOGISTICA">Logística</option>-->
          <!--              <option value="OPERACIONAL">Operacional</option>-->
          <!--              <option value="PRODUCAO">Produção</option>-->
          <!--              <option value="DIRETORIA">Diretoria</option>-->
          <!--              <option value="CONTABIL">Contábil</option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--          <div class="span-3 mt-1">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="facebookURL"-->
          <!--              placeholder="Informe o seu Facebook"-->
          <!--              label="Facebook"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.facebookURL"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-3 mt-1">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="instagramURL"-->
          <!--              placeholder="Informe o seu Instagram"-->
          <!--              label="Instagram"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.instagramURL"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-3 mt-1">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="twitterURL"-->
          <!--              placeholder="Informe o seu Twitter"-->
          <!--              label="Twitter"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.twitterURL"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="span-3 mt-1">-->
          <!--            <Input-->
          <!--              type="text"-->
          <!--              name="linkedinURL"-->
          <!--              placeholder="Informe o seu LinkedIn"-->
          <!--              label="LinkedIn"-->
          <!--              className="text-bold"-->
          <!--              v-model="userData.linkedInURL"-->
          <!--            />-->
          <!--          </div>-->

          <div class="span-12">
            <hr/>
          </div>

          <div class="span-12">
            <h3 class="mb-1">RECEBIMENTO DE FOLLOW UP</h3>
            <p class="text-light">Configurações de recebimento de Follow UP</p>
          </div>

          <div class="span-12">
            <label>Receber Follow Up?</label>
            <Toggle
              name="sit-siscarga"
              v-model="userData.followUP"
              :labels="{
                checked: 'Sim',
                unchecked: 'Não'
              }"
            />
          </div>

          <transition name="pages">
            <div class="span-12" v-if="userData.followUP">
              <label>Escolha o tipo de Follow Up</label>
              <div class="flex ai-c fw-w fgap2">
                <Toggle
                  name="sit-siscarga"
                  v-model="userData.followUPAnalytical"
                  :labels="{
                    checked: 'Follow Up Analítico',
                    unchecked: 'Follow Up Analítico'
                  }"
                />

                <Toggle
                  name="sit-siscarga"
                  v-model="userData.followUPSynthetic"
                  :labels="{
                    checked: 'Follow Up Sintético',
                    unchecked: 'Follow Up Sintético'
                  }"
                />
              </div>
            </div>
          </transition>

          <transition name="pages">
            <div class="span-12" v-if="userData.followUPSynthetic">
              <v-select
                v-model="userData.followUPSyntheticWeekday"
                :options="followUPSyntheticWeekdayList"
                placeholder="Dia de recebimento do Follow Up sintético"
              ></v-select>
            </div>
          </transition>

          <div class="span-12">
            <button class="btn solid primary full-width">ATUALIZAR MEUS DADOS</button>
          </div>
        </form>

        <div class="span-4 pl-3">
          <div class="medium-border-radius offwhite-bg pv-1 ph-2 flex ai-c jc-sb mb-2">
            <div>
              <h4 class="text-uppercase text-medium mb-1">Empresas Disponíveis</h4>
              <p class="text-light">Empresas que você tem acesso</p>
            </div>
          </div>

          <ul class="company-list">
            <CustomerList v-for="customer in customers" :key="customer.id" :customer="customer"/>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';

import Input from '@/components/DataInput/Input.vue';
import Toggle from '@/components/DataInput/Toggle.vue';
import CustomerList from '@/components/CustomerList.vue';

export default {
  name: 'MyData',
  components: {
    // Components
    PageTitle,
    Input,
    Toggle,
    CustomerList,
  },
  data() {
    return {
      showForm: false,
      userData: {},
      changePassword: false,
      oldEmail: '',
      followUPSyntheticWeekdayList: [
        { value: 'MONDAY', label: 'Segunda Feira' },
        { value: 'TUESDAY', label: 'Terça Feira' },
        { value: 'WEDNESDAY', label: 'Quarta Feira' },
        { value: 'THURSDAY', label: 'Quinta Feira' },
        { value: 'FRIDAY', label: 'Sexta Feira' },
        { value: 'SATURDAY', label: 'Sábado' },
        { value: 'SUNDAY', label: 'Domingo' },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    customers() {
      if (this.user.companies) {
        return this.user.companies.filter((a) => a.customer);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['logout', 'updateUser']),
    async getUserData() {
      if (this.user) {
        this.userData = this.user;
        this.followUP = this.user.followUP;
        this.oldEmail = this.user.email;
      }
    },
    async updateUserData() {
      const dataProfile = {
        id: this.userData.id,
        name: this.userData.name,
        email1: this.userData.email,
        // password: this.changePassword ? this.userData.password : null,
        // repeatedPassword: this.changePassword ? this.userData.repeatedPassword : null,
        followUP: this.followUP ? this.followUP : false,
        followUPAnalytical: this.userData.followUPAnalytical,
        followUPSynthetic: this.userData.followUPSynthetic,
        // dateOfBirth: this.userData.dateOfBirth ? `${this.userData.dateOfBirth}T00:00:00` : null,
        // hiringDate: this.userData.hiringDate !== 'Invalid date' ? this.userData.hiringDate : null,
        // sector: this.userData.sector !== '' && this.userData ? this.userData.sector : null,
        // jobRole: this.userData.jobRole,
        // telephone1: this.userData.telephone1,
        // facebookURL: this.userData.facebookURL,
        // instagramURL: this.userData.instagramURL,
        // twitterURL: this.userData.twitterURL,
        // linkedInURL: this.userData.linkedInURL,
        // followUPSyntheticWeekday: this.userData.followUPSyntheticWeekday !== 'undefined' && this.userData.followUPSyntheticWeekday !== null
        //   ? this.userData.followUPSyntheticWeekday.value
        //   : null,
      };

      if (this.userData.followUPSyntheticWeekday !== 'undefined' && this.userData.followUPSyntheticWeekday !== null) {
        dataProfile.followUPSyntheticWeekday = this.userData.followUPSyntheticWeekday.value;
      }

      if (this.changePassword) {
        dataProfile.password = this.userData.password;
        dataProfile.repeatedPassword = this.userData.repeatedPassword;
      }

      if (this.changePassword) {
        if (
          (dataProfile.password !== 'undefined' && dataProfile.password !== '')
          && (dataProfile.repeatedPassword !== 'undefined' && dataProfile.repeatedPassword !== '')
        ) {
          if (dataProfile.password !== dataProfile.repeatedPassword) {
            this.$toast.error('As senhas devem ser iguais.');
          } else {
            const response = await api.patch('/credential/updateProfile', dataProfile);

            if (response.data.code === 'SUCCESS') {
              this.$toast.success('Sua senha foi alterada com sucesso, você redirecionado para a página de login', {
                position: 'top-center',
              });
            }

            setTimeout(() => {
              this.logout();
            }, 3000);
          }
        } else {
          this.$toast.error('A senha não pode ser vazia.');
        }
      } else {
        const response = await api.patch('/credential/updateProfile', dataProfile);

        const emailAlterado = this.oldEmail !== dataProfile.email1;
        const message = emailAlterado
          ? 'Seu email foi alterado com sucesso, você será redirecionado para a página de login'
          : 'Dadaos alterados com sucesso';

        if (response.data.code === 'SUCCESS') {
          this.$toast.success(message, {
            position: 'top-center',
          });
        }

        if (emailAlterado) {
          setTimeout(() => {
            this.logout();
          }, 3000);
        }
      }
    },
  },
  async created() {
    await this.toggleLoading(true);
    if (localStorage.getItem('@GSCMX-PC:access_token')) {
      const userResponse = await api.get('/credential');
      await this.updateUser(userResponse.data.data);
      await this.getUserData();
    }
    await this.toggleLoading(false);
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.user-form {
  border-right: var(--input-border);
}

.company-list {
  max-height: 450px;
  overflow: auto;
}
</style>
