<template>
  <div class="h-card text-white pv-1 ph-2 medium-border-radius flex ai-c jc-sb" :style="`background-color: ${background};`">
    <div class="main-info">
      <span class="text-xg text-medium" style="margin-right: 5px;">
        <span v-if="animated">
          <number
            ref="number1"
            :from="0"
            :to="numero"
            :duration="5"
            :delay="1"
            easing="Power1.easeOut"/>
        </span>
        <span v-else>{{numero}}</span>
      </span>
      <span class="text-md text-medium">{{sigla}}</span>
      <p class="text-thin">{{descricao}}</p>
    </div>

    <div class="icon">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalInfoCard',
  props: {
    numero: {
      type: [Number, String],
      default: 99,
    },
    sigla: {
      type: String,
      default: 'EX',
    },
    descricao: {
      type: String,
      default: 'exemplo',
    },
    background: {
      type: String,
      default: '#004385',
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
