<template>
  <transition mode="out-in" name="cookies">
    <div v-if="open" class="cookies-policy text-sm">
      <p class="mb-1 text-md" style="flex: 0 0 100%">
        <b>Sobre os Cookies do Portal dos Clientes</b>
      </p>
      <div style="flex: 0 0 40%">
        <p>
          O Portal dos Clientes requerer Cookies para funcionar corretamente, eles são fundamentais para
          o funcionamento, análise e experiência do usuário durante a navegação.
        </p>
      </div>

      <div style="flex: 0 0 40%">
        <p>
          Para obter mais informações, clique nos links a seguir para conhecer as nossas políticas
          de
          <a
            href="https://lgpd.prime.com.vc/index#content"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            PRIVACIDADE
          </a>,
          <a
            href="https://lgpd.prime.com.vc/politica-de-cookies#content"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            COOKIES
          </a>,
          <a
            href="https://lgpd.prime.com.vc/politica-de-retencao-e-descarte-de-dados-pessoais#content"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            RETENÇÃO DE DADOS
          </a>
          ou
          <a
            href="https://lgpd.prime.com.vc/categoria-de-dados-lgpd#content"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            CATEGORIA DE DADOS
          </a>
        </p>
      </div>

      <div class="cookies-actions" style="flex: 0 0 20%">
        <button class="btn solid primary small" @click.prevent="acceptCookies">Aceitar</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookiePolicy',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    getCookieConsent() {
      const cookies = document.cookie.split(';');

      let ckExists = false;

      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i].split('=');
        const cookieName = cookie[0].trim();

        if (cookieName === 'consent') {
          ckExists = true;
        }
      }

      if (ckExists === false) {
        this.open = true;
      }
    },
    acceptCookies() {
      const hoje = new Date();
      let expires = new Date(hoje.getTime() + 365 * 24 * 60 * 60 * 1000);
      expires = expires.toGMTString();
      document.cookie = `consent=true; expires=${expires}; path=/`;
      this.open = false;
    },
  },
  mounted() {
    this.getCookieConsent();
  },
};
</script>

<style scoped>
.cookies-policy {
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 99999;
  border: 2px solid transparent;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cookies-policy a {
  text-transform: uppercase;
}

.cookies-policy > div {
  padding: 0.5rem 1rem 1rem;
}

.cookies-policy > p {
  padding: 1rem 1rem 0 1rem;
}

.cookies-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .cookies-policy {
    flex-direction: column;
    text-align: center;
  }

  .cookies-policy > div {
    padding: 0.5rem 1rem;
  }

  .cookies-policy > div,
  .cookies-policy > p {
    width: 100%;
    text-align: center;
  }
}

.cookies-enter-active,
.cookies-leave-active {
  transition: all 2s ease-in-out 0s;
}

.cookies-enter,
.cookies-leave-to {
  opacity: 0;
  transform: translate3d(0, 50vh, 0);
}

.cookies-enter-to,
.cookies-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: var(--all-transition);
}
</style>
