<template>
  <li>
    <div class="flex ai-c jc-sb fgap2 pv-1 ph-2 medium-border-radius">
      <div class="flex ai-c jc-sb fgap2">
        <div v-if="customer !== null"
             :class="`customer-profile flex ai-c jc-c text-bg light-bg text-bold text-white ${testChar(customer.name.split('')[0])}`">
          {{ customer.name.split('')[0] }}
        </div>
        <div>
          <p class="text-medium" :class="{ blured: presentationMode }">{{ customer.name }}</p>
          <p class="text-light text-sm" :class="{ blured: presentationMode }">{{
              customer.federalID
            }}</p>
        </div>
      </div>

      <div class="actions relative" v-if="showActions">
        <div class="pointer p-1" @click.prevent="actionsOpen = !actionsOpen">
          <VerticalDotsIcon/>
        </div>
        <transition name="notifications">
          <div class="actions-menu" v-if="actionsOpen">
            <router-link :to="`#${customer.id}`">
              Editar
            </router-link>
            <a href="#" @click.prevent>Remover</a>
          </div>
        </transition>
      </div>
    </div>
  </li>
</template>

<script>
import { VerticalDotsIcon } from '@/components/Icons';
import { mapState } from 'vuex';

export default {
  name: 'CustomerList',
  components: {
    VerticalDotsIcon,
  },
  data() {
    return {
      actionsOpen: false,
    };
  },
  methods: {
    testChar(letter) {
      const reg = new RegExp(letter, 'gi');

      if ('abcdefghi'.match(reg)) {
        return 'first-color';
      }

      if ('jklmnopqr'.match(reg)) {
        return 'second-color';
      }

      return 'third-color';
    },
  },
  computed: {
    ...mapState(['presentationMode']),
  },
  mounted() {
    window.addEventListener('click', (event) => {
      if (!event.target.closest('.actions')) {
        this.actionsOpen = false;
      }
    });
  },
  props: {
    customer: {
      type: Object,
      default: () => {
      },
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.customer-profile {
  width: 4rem;
  height: 4rem;
  border-radius: var(--round-border-radius);
}

.customer-profile.first-color {
  background-color: #87A1BA;
}

.customer-profile.second-color {
  background-color: #EDD7AB;
}

.customer-profile.third-color {
  background-color: #BDC8D7;
}

ul li:nth-of-type(even) {
  background-color: #FAFBFC;
}

.actions-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white-color);
  overflow: hidden;
  border-radius: var(--medium-border-radius);
  z-index: 9;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.actions-menu a {
  display: block;
  padding: .75rem 1rem;
}

.actions-menu a:not(:last-of-type) {
  border-bottom: var(--input-border);
}
</style>
