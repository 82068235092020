<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4326 18.5456C2.45348 18.5459 2.47414 18.5463 2.49502 18.5463C3.20469 18.5463 3.86337 18.2588 4.32249 17.7892C4.76051 18.2999 5.43633 18.618 6.20381 18.6362C6.22556 18.6364 6.24622 18.6369 6.26798 18.6369C6.97392 18.6369 7.62886 18.352 8.08776 17.8865C8.52447 18.3933 9.20227 18.7092 9.9748 18.7272C9.99589 18.7274 10.0174 18.7281 10.0388 18.7281C10.7484 18.7281 11.4064 18.4406 11.8651 17.9711C12.3031 18.4821 12.9788 18.8001 13.7471 18.8184C13.7682 18.8188 13.7886 18.8191 13.8091 18.8191C14.5137 18.8191 15.1671 18.536 15.6258 18.0729C16.0612 18.5764 16.7412 18.8905 17.5179 18.9094C17.539 18.9098 17.5594 18.91 17.5801 18.91C18.5926 18.91 19.51 18.334 19.8755 17.4623C19.9746 17.2267 19.8634 16.9555 19.6278 16.8569C19.3911 16.7573 19.1205 16.8687 19.0223 17.1043C18.795 17.6461 18.1979 17.9938 17.5401 17.9839C16.8662 17.9683 16.3352 17.6531 16.1344 17.1621C16.1227 16.9927 16.0229 16.8358 15.8561 16.7654C15.6188 16.6676 15.3491 16.7784 15.2504 17.0133C15.0238 17.5553 14.4284 17.9081 13.7684 17.8929C13.0999 17.878 12.548 17.532 12.3517 17.0127C12.3225 16.8674 12.2324 16.7366 12.0858 16.6747C11.8502 16.5758 11.579 16.6863 11.4799 16.9219C11.2522 17.4641 10.655 17.8201 9.99655 17.8019C9.33084 17.7867 8.78381 17.4538 8.58644 16.9531C8.56578 16.7951 8.4715 16.6507 8.31414 16.5843C8.07809 16.4854 7.80732 16.5966 7.7082 16.8316C7.48051 17.3736 6.88227 17.719 6.22535 17.7109C5.55699 17.6955 5.00469 17.35 4.80842 16.8296C4.77875 16.685 4.68886 16.5547 4.54205 16.4929C4.30403 16.3947 4.0348 16.5061 3.93611 16.741C3.70952 17.2828 3.11611 17.6309 2.45458 17.6206C1.79611 17.605 1.22315 17.2254 1.0293 16.6766C0.944026 16.4355 0.678752 16.3092 0.438752 16.3947C0.197653 16.4804 0.0714989 16.7448 0.156774 16.9854C0.478752 17.8933 1.39304 18.5206 2.4326 18.5456Z"
      fill="currentColor"
    />
    <path
      d="M1.83442 3.99614C2.40453 4.11636 2.94738 3.95372 3.42607 3.81021C3.82167 3.69175 4.19552 3.57944 4.52826 3.63416C4.65617 3.65526 4.76277 3.70054 4.82826 3.76208C4.88013 3.81087 4.90343 3.8735 4.89903 3.90779C4.86628 4.16098 5.04497 4.39328 5.29837 4.42603C5.31837 4.42867 5.33859 4.42999 5.35837 4.42999C5.56563 4.42999 5.74672 4.29043 5.80211 4.09109C5.80497 4.08295 5.80782 4.07438 5.81046 4.06295C5.8131 4.05065 5.81398 4.03944 5.81617 4.02713C5.81617 4.02691 5.81639 4.02691 5.81639 4.02669V4.02647C5.95134 3.34823 5.83332 2.80669 5.46299 2.4179C4.79903 1.72186 3.56387 1.8346 3.06255 1.9157C2.62277 1.98669 2.13859 1.76098 1.91178 1.3768C1.78145 1.15702 1.49815 1.08405 1.27793 1.21416C1.05815 1.34427 0.984967 1.62801 1.1153 1.84801C1.5353 2.5579 2.39618 2.96208 3.21156 2.82867C3.35398 2.80559 3.51046 2.78977 3.6687 2.78317C3.49288 2.82471 3.32299 2.87504 3.16101 2.92339C2.75112 3.04603 2.36343 3.16186 2.02475 3.09021C1.80013 3.04339 1.59332 2.90823 1.45705 2.71966C1.30826 2.51262 1.01881 2.46559 0.811341 2.61482C0.604088 2.76427 0.557055 3.0535 0.706505 3.26054C0.975516 3.6346 1.38716 3.90251 1.83442 3.99614Z"
      fill="currentColor"
    />
    <path
      d="M13.8408 7.12366H15.6272C15.7294 7.12366 15.8122 7.04081 15.8122 6.93861V5.38476C15.8122 5.28256 15.7294 5.19971 15.6272 5.19971H13.8408C13.7386 5.19971 13.6558 5.28256 13.6558 5.38476V6.93839C13.6558 7.04059 13.7386 7.12366 13.8408 7.12366Z"
      fill="currentColor"
    />
    <path
      d="M12.9152 5.19995H11.1284C11.0262 5.19995 10.9434 5.28281 10.9434 5.38501V6.93863C10.9434 7.04061 11.0262 7.12369 11.1284 7.12369H12.9152C13.0174 7.12369 13.1003 7.04083 13.1003 6.93863V5.38479C13.1001 5.28281 13.0174 5.19995 12.9152 5.19995Z"
      fill="currentColor"
    />
    <path
      d="M16.8674 7.67871H15.0811C14.9789 7.67871 14.896 7.76157 14.896 7.86377V9.41717C14.896 9.51915 14.9789 9.60223 15.0811 9.60223H15.9334C16.0083 9.60223 16.0758 9.55717 16.1043 9.48816L16.3406 8.91871C16.3837 8.81476 16.4843 8.74772 16.5969 8.74772H16.8672C16.9694 8.74772 17.0523 8.66486 17.0523 8.56267V7.86377C17.0525 7.76157 16.9696 7.67871 16.8674 7.67871Z"
      fill="currentColor"
    />
    <path
      d="M12.1841 7.86377V9.41717C12.1841 9.51915 12.2669 9.60223 12.3691 9.60223H14.156C14.2582 9.60223 14.341 9.51937 14.341 9.41717V7.86377C14.341 7.76157 14.2582 7.67871 14.156 7.67871H12.3691C12.2667 7.67871 12.1841 7.76157 12.1841 7.86377Z"
      fill="currentColor"
    />
    <path
      d="M3.73397 7.79368L3.09881 9.34731C3.07529 9.40445 3.0821 9.46929 3.11639 9.52071C3.15068 9.5717 3.20826 9.60247 3.27002 9.60247H7.97199C8.03353 9.60247 8.09111 9.5717 8.12562 9.52071C8.15991 9.46951 8.16672 9.40467 8.1432 9.34731L7.50782 7.7939C7.47947 7.72445 7.41177 7.67896 7.33661 7.67896H3.90518C3.83002 7.67874 3.76232 7.72423 3.73397 7.79368Z"
      fill="currentColor"
    />
    <path
      d="M16.0898 12.2566H2.38538C2.32428 12.2566 2.26736 12.2867 2.23285 12.3369C2.19835 12.387 2.19087 12.4509 2.21241 12.5078L3.34032 15.4531C3.359 15.5021 3.39791 15.5408 3.44692 15.5597C3.49615 15.5784 3.55087 15.5753 3.59791 15.5516C3.82164 15.4366 4.02076 15.2905 4.19021 15.1166C4.22428 15.0819 4.2656 15.0595 4.32824 15.0617C4.3801 15.0632 4.42911 15.0865 4.46274 15.1261C4.87571 15.607 5.51153 15.8918 6.20933 15.9083L6.26801 15.9092C6.9056 15.9092 7.52098 15.6558 7.95571 15.2142C7.98054 15.1889 8.0223 15.159 8.09307 15.159C8.09351 15.159 8.09395 15.159 8.09439 15.159C8.1456 15.1608 8.19439 15.1839 8.22823 15.2232C8.63922 15.6999 9.27747 15.9828 9.9812 15.9995L10.0388 16.0001C10.6801 16.0001 11.2975 15.7448 11.7326 15.2993C11.7676 15.2634 11.8142 15.2437 11.8707 15.2437C11.8709 15.2437 11.8711 15.2437 11.8713 15.2437C11.923 15.2452 11.9715 15.2685 12.0052 15.3083C12.4181 15.7892 13.0546 16.0744 13.7531 16.0907L13.8067 16.0914C13.8074 16.0914 13.8082 16.0914 13.8089 16.0914C14.4445 16.0914 15.0588 15.8395 15.4937 15.4001C15.5298 15.3641 15.5715 15.3456 15.6302 15.3456C15.6307 15.3456 15.6313 15.3456 15.632 15.3456C15.6832 15.3472 15.7315 15.37 15.7652 15.4094C15.9183 15.5865 16.1054 15.7382 16.3203 15.8608C16.3702 15.8889 16.4302 15.8929 16.4825 15.8709C16.5355 15.8489 16.5753 15.8041 16.5902 15.7489C16.5946 15.7336 16.597 15.7184 16.599 15.7001L19.0304 9.55619C19.0531 9.49927 19.0456 9.43487 19.0113 9.3841C18.9768 9.33355 18.9194 9.30322 18.8583 9.30322H16.9063C16.8315 9.30322 16.7641 9.34828 16.7353 9.41751L16.499 9.98674C16.4559 10.0909 16.3555 10.1577 16.2427 10.1577H1.58164C1.52076 10.1577 1.46362 10.1878 1.42911 10.2379C1.39461 10.2881 1.38736 10.352 1.40889 10.4089L1.85813 11.5823C1.8856 11.654 1.95439 11.7012 2.03109 11.7012H16.0898C16.2427 11.7012 16.3674 11.8256 16.3674 11.9788C16.3674 12.132 16.2427 12.2566 16.0898 12.2566Z"
      fill="currentColor"
    />
    <path
      d="M9.47168 7.86377V9.41717C9.47168 9.51915 9.55454 9.60223 9.65673 9.60223H11.4435C11.5457 9.60223 11.6288 9.51937 11.6288 9.41717V7.86377C11.6288 7.76157 11.5457 7.67871 11.4435 7.67871H9.65673C9.55454 7.67871 9.47168 7.76157 9.47168 7.86377Z"
      fill="currentColor"
    />
    <path
      d="M6.07006 7.12363C6.37665 7.12363 6.62544 6.87484 6.62544 6.56825V5.7766C6.62544 5.47001 6.37665 5.22144 6.07006 5.22144H5.1716C4.865 5.22144 4.61621 5.47001 4.61621 5.7766V6.56825C4.61621 6.87484 4.865 7.12363 5.1716 7.12363H6.07006Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShipMiniIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
