<template>
  <div class="login-page full-height flex ai-c jc-c fd-c fgap2">
    <router-link :to="{ name: 'Login' }">
      <img src="@/assets/img/logo-prime.svg" alt="Prime Internacional">
    </router-link>

    <div class="offwhite-bg medium-border-radius pv-4 ph-6 text-center flex fd-c jc-c">
      <h1 class="mb-1 text-xg text-bold">Recuperação de senha</h1>
      <p class="mb-3">Digite o seu email redefinir a senha</p>

      <form class="flex fd-c fgap2">
        <Input
          label="Email"
          type="email"
          name="email"
          placeholder="nome@email.com.br"
          :required="true"
          v-model="userData.email"
          className="input-login"
        />

        <button type="submit" class="btn solid primary full-width extra-big" @click.prevent="() => recovery()">RECUPERAR SENHA</button>
      </form>

      <router-link :to="{ name: 'Login' }" class="mt-2">Voltar para login</router-link>
    </div>

    <cookies-policy />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Input from '@/components/DataInput/Input.vue';
import CookiesPolicy from '../../components/CookiesPolicy.vue';

/* eslint-disable */
import api from '@/services/api';

export default {
  name: 'Login',
  components: {
    CookiesPolicy,
    Input,
  },
  data() {
    return {
      userData: {
        email: '',
      },
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    async recovery() {
      this.toggleLoading(true);

      try {
        const response = await api.post('/credential/recovery/password', { email: this.userData.email }, {
          headers: { Authorization: '' },
        });
        if (response && response.data.code === 'SUCCESS') {
          this.$toast.success(response.data.message, {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 10000,
          });
          this.$router.push('/codigo-verificacao');
        }
      } catch (err) {
        this.$toast.error(`Não foi possível executar esta ação no momento: ${err} `);
      }
      this.toggleLoading(false);
    },
  },
};
</script>

<style scoped>
  .login-page {
    background: var(--primary-color);
  }

  form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1300px) {
    .login-page.columns {
      grid-template-columns: 1fr;
      background: var(--white-color);
    }
  }
</style>
