<script>
export default {
  name: 'GsCalendarItem',
  props: {
    day: {
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    otherMonth: {
      type: Boolean,
      default: false,
    },
    past: {
      type: Boolean,
      default: false,
    },
    future: {
      type: Boolean,
      default: false,
    },
    long: {
      type: Boolean,
      default: false,
    },
    overview: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
};
</script>

<template>
  <li
    class="calendar-item"
    :class="{
      active: active,
      other: otherMonth,
      past: past && !active,
      future: future,
      long: long,
      overview: overview,
      'no-border': noBorder
    }"
  >
    <p class="day">
      {{ day }}
      <span v-if="active" class="text-normal"> hoje</span>
    </p>
    <div class="calendar-item-content">
      <slot/>
    </div>
  </li>
</template>

<style scoped>
.calendar-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 14.28%;
  padding: 0.5rem;
  border-bottom: 1px solid #F4F7F9;
  border-right: 1px solid #F4F7F9;
  /* overflow: auto; */
  /* aspect-ratio: 1/1; */
  min-height: 246px;
}

.calendar-item.overview {
  background-color: #FCFDFD;
  border-right: 0;
}

.calendar-item.long {
  aspect-ratio: unset;
  height: 70vh;
}

.calendar-item.past {
  opacity: 0.5;
  background-color: #f5f6f7;
}

.calendar-item.active {
  background-color: #ebf5ff;
}

.calendar-item.other .day {
  color: #aaaaaa;
}

.calendar-item.no-border {
  border: 0;
}

.calendar-item .calendar-item-content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.calendar-item .day {
  font-weight: 600;
  font-size: 0.8rem;
  color: #6a6a6a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.totals {
  color: black;
}
</style>
