<template>
  <div class="dropdown-menu" :class="classDropDownMenu">
    <div @click.prevent="toggleDropDown" class="dropdown-btn" type="button">
      <span>{{ label }}</span>
      <chevron-down-icon size="1.5rem"/>
    </div>

    <transition name="notifications">
      <div v-if="open" class="dropdown-content" :class="[contentPosition]"
           :style="{ width: `${contentWidth}`}">
        <slot name="content" :close="closeDropDown"/>
      </div>
    </transition>
  </div>
</template>

<script>
import ChevronDownIcon from './Icons/ChevronDownIcon.vue';

export default {
  components: { ChevronDownIcon },
  name: 'DropDown',
  props: {
    label: {
      type: String,
      required: true,
    },
    contentWidth: {
      type: String,
      default: '100px',
    },
    contentPosition: {
      type: String,
      default: 'right',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    classDropDownMenu: {
      string: true,
      default: 'dropdown-menu',
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.open = !this.open;
    },
    closeDropDown() {
      this.open = false;
    },
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest(`.${this.classDropDownMenu}`)) {
        this.closeDropDown();
      }
    });
  },
};
</script>

<style scoped>
.dropdown-menu {
  position: relative;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  font-family: Poppins, sans-serif;
  border-radius: 100px;
  background-color: #F4F7F9;
  color: var(--primary-color);
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: var(--all-transition);
  text-transform: uppercase;
  font-size: 0.85rem;
  height: 3.125rem;
}

.dropdown-btn:hover {
  background-color: var(--primary-color);
  color: #F4F7F9;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 0.5rem);
  z-index: 99;

  background-color: var(--white-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--medium-border-radius);
}

.dropdown-content.right {
  right: 0;
}

.dropdown-content.left {
  left: 0;
}
</style>
