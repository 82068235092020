// import Vue from 'vue';
import axios from 'axios';
import router from '../router';

const { VUE_APP_API_HOST: host } = process.env;

const api = axios.create({
  baseURL: `${host}`,
});

api.defaults.headers.common['App-Source'] = 'WEBPC';

api.interceptors.request.use(async (config) => {
  if (localStorage.getItem('@GSCMX-PC:access_token')) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('@GSCMX-PC:access_token'),
    };
  }
  return config;
}, (error) => {
  Promise.reject(error);
});

api.interceptors.response.use((response) => {
  if (response.data.access_token) {
    localStorage.setItem('@GSCMX-PC:access_token', `Bearer ${response.data.access_token}`);
  }
  if (response.data.refresh_token) {
    localStorage.setItem('@GSCMX-PC:refresh_token', response.data.refresh_token);
  }
  return response;
});

api.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 400) {
    // Vue.$toast.error(error.response.data.error_description);
    return Promise.reject(error);
    // throw new Error(error.response.data.error_description);
  }

  if (error?.response?.status === 401) {
    localStorage.removeItem('@GSCMX-PC:access_token');
    localStorage.removeItem('@GSCMX-PC:refresh_token');
    router.push({ name: 'Login' });
  }

  return Promise.reject(error);
});

export default api;
