import Vue from 'vue';
import api from '@/services/api';

const store = {
  namespaced: true,
  state: {
    leadtimesIndicators: [],
    stepsIndicators: [],
    diRegistrationIndicators: [],
    proceduralMovimentsIndicatorsAtualPeriod: [],
    timeStorageIndicators: [],
  },
  mutations: {
    UPDATE_LEADTIMES_INDICATORS(state, payload) {
      state.leadtimesIndicators = payload;
    },
    UPDATE_STEPS_INDICATORS(state, payload) {
      state.stepsIndicators = payload;
    },
    UPDATE_DI_REGISTRATION(state, payload) {
      state.diRegistrationIndicators = payload;
    },
    UPDATE_PROCEDURAL_MOVIMENTS_INDICATORS_ATUAL_PERIOD(state, payload) {
      state.proceduralMovimentsIndicatorsAtualPeriod = payload;
    },
    UPDATE_TIME_STORAGE_INDICATORS(state, payload) {
      state.timeStorageIndicators = payload;
    },
  },
  actions: {
    async getLeadtimesIndicators(context, payload) {
      try {
        const route = `/api/public/customer/analytics/leadtime/all?customersId=${payload.companiesId}&firstDate=${payload.startDate}&secondDate=${payload.endDate}&teamId=0`;
        const response = await api.get(route);

        context.commit('UPDATE_LEADTIMES_INDICATORS', response.data.data);
      } catch (err) {
        Vue.$toast.error('Não foi possível recuperar os indicadores de Lead times');
      }
    },
    async getStepsIndicators(context, payload) {
      try {
        const route = `/api/public/customer/analytics/total/all?customersId=${payload.companiesId}&teamId=0`;
        const response = await api.get(route);

        context.commit('UPDATE_STEPS_INDICATORS', response.data.data);
      } catch (err) {
        Vue.$toast.error('Não foi possível recuperar os indicadores de etapas');
      }
    },
    async getDiRegistrationIndicators(context, payload) {
      try {
        const route = `/api/public/customer/analytics/registroDi?customersId=${payload.companiesId}&firstDate=${payload.startDate}&secondDate=${payload.endDate}&teamId=0`;
        const response = await api.get(route);

        context.commit('UPDATE_DI_REGISTRATION', response.data.data);
      } catch (err) {
        Vue.$toast.error('Não foi possível recuperar os indicadores de registro de DI');
      }
    },
    clearAllIndicators(context) {
      context.commit('UPDATE_LEADTIMES_INDICATORS', []);
      context.commit('UPDATE_STEPS_INDICATORS', []);
      context.commit('UPDATE_DI_REGISTRATION', []);
      context.commit('UPDATE_PROCEDURAL_MOVIMENTS_INDICATORS_ATUAL_PERIOD', []);
    },
    async getProceduralMovimentsIndicatorsAtualPeriod(context, payload) {
      try {
        const route = `/api/public/customer/analytics/movimentacaoProcessual/all?customersId=${payload.companiesId}&firstDate=${payload.startDate}&secondDate=${payload.endDate}&teamId=0&isVariacaoAbertura=false`;
        const response = await api.get(route);

        context.commit('UPDATE_PROCEDURAL_MOVIMENTS_INDICATORS_ATUAL_PERIOD', response.data.data);
      } catch (err) {
        Vue.$toast.error('Não foi possível recuperar os indicadores de registro de movimentação de processual');
      }
    },
    async getTimeStorageIndicators(context, payload) {
      try {
        const route = `/api/public/customer/analytics/periodoDeArmazenagem/all?customersId=${payload.companiesId}&firstDate=${payload.startDate}&secondDate=${payload.endDate}&teamId=0`;
        const response = await api.get(route);

        context.commit('UPDATE_TIME_STORAGE_INDICATORS', response.data.data);
      } catch (err) {
        Vue.$toast.error('Não foi possível recuperar os indicadores de período de armazenagem');
      }
    },
  },
};

export default store;
