<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.20608 2.25504C6.20608 2.00405 6.00257 1.80054 5.75158 1.80054H2.95158C2.70059 1.80054 2.49707 2.00427 2.49707 2.25504C2.49707 2.50625 2.70059 2.70955 2.95158 2.70955H5.75136C6.00257 2.70977 6.20608 2.50625 6.20608 2.25504Z"
      fill="currentColor"
    />
    <path
      d="M1.39469 6.43738H3.16084C3.15909 6.46617 4.02194 9.91144 4.02194 9.91144C4.02194 9.91144 3.15755 13.2668 3.15755 13.3055C3.15755 14.2717 4.11975 14.2958 4.53073 14.3062C4.53447 14.3062 4.53843 14.3062 4.54216 14.3062C5.12898 14.3062 5.32546 14.0053 5.51513 13.7139L6.69491 11.8418L17.7301 11.8198C18.7509 11.8189 19.8455 11.0556 19.8463 9.92155C19.8468 9.39342 19.6055 8.8987 19.1672 8.52991C18.7701 8.19474 18.2466 8.00287 17.7296 8.00287L6.69601 7.98881L5.59887 6.16067C5.33821 5.72023 4.97513 5.51122 4.52898 5.52859C4.51909 5.52947 1.39469 5.52815 1.39469 5.52815C1.1437 5.52815 0.940186 5.73188 0.940186 5.98265C0.940186 6.23386 1.1437 6.43738 1.39469 6.43738Z"
      fill="currentColor"
    />
    <path
      d="M0.608314 8.77913H2.13623C2.38722 8.77913 2.59073 8.57583 2.59073 8.32462C2.59073 8.07385 2.38722 7.87012 2.13623 7.87012H0.608314C0.357325 7.87012 0.153809 8.07385 0.153809 8.32462C0.153809 8.57605 0.357325 8.77913 0.608314 8.77913Z"
      fill="currentColor"
    />
    <path
      d="M3.73999 17.7674C3.73999 18.0186 3.94351 18.2219 4.1945 18.2219H10.9213C10.9222 18.2219 10.9222 18.2219 10.9226 18.2219C12.0804 18.2201 12.4679 17.5581 12.7508 17.0748L12.7864 17.0144C12.7941 17.001 12.8013 16.9874 12.8079 16.9735L14.5936 13.0896C14.6585 12.9487 14.6468 12.7852 14.5635 12.6546C14.4802 12.5245 14.336 12.4452 14.1811 12.4447L9.7923 12.4395C9.79208 12.4395 9.79186 12.4395 9.79164 12.4395C9.55801 12.4395 9.36263 12.6168 9.33911 12.8485L8.99757 16.25C8.99603 16.2654 8.99537 16.2806 8.99537 16.2955C8.99515 16.6946 9.05955 17.0342 9.18702 17.3129H4.1945C3.94329 17.3126 3.73999 17.5162 3.73999 17.7674Z"
      fill="currentColor"
    />
    <path
      d="M6.37046 15.375H2.13639C1.88518 15.375 1.68188 15.579 1.68188 15.8295C1.68188 16.0807 1.8854 16.284 2.13639 16.284H6.37068C6.62166 16.284 6.82518 16.0805 6.82518 15.8295C6.82518 15.579 6.62144 15.375 6.37046 15.375Z"
      fill="currentColor"
    />
    <path
      d="M9.68238 5.31463C9.55403 5.31463 9.43139 5.36935 9.34524 5.4643C9.25908 5.5599 9.2171 5.68716 9.23007 5.81507L9.34612 6.96672C9.36963 7.19946 9.56502 7.37595 9.79842 7.37595H14.1808C14.3353 7.37595 14.4795 7.29749 14.5633 7.16738C14.6466 7.03727 14.6586 6.87353 14.5942 6.73265L12.8226 2.8465C12.8162 2.83221 12.8092 2.81858 12.8011 2.80474L12.7668 2.74562C12.4844 2.2621 12.098 1.60034 10.9378 1.60034C9.65842 1.60034 9.00941 2.24891 9.00809 3.52825C9.00809 3.53727 9.00831 3.5465 9.00875 3.55529L9.01622 3.68364H4.71183C4.46084 3.68364 4.25732 3.88738 4.25732 4.13814C4.25732 4.38935 4.46084 4.59265 4.71183 4.59265H9.4982H10.4545H11.2006C11.1916 4.74496 11.0925 5.03573 10.9551 5.31441H9.68238V5.31463Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'AirplaneMiniIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
