<template>
  <div>
    <PageTitle :breadcrumbName="breadcrumbName" :pageTitle="pageTitle" class="mb-2" />

    <div class="card">
      <div class="flex ai-c jc-sb fgap2">
        <PageTitle
          breadcrumbName=""
          subtitle="Visualize a previsão financeira no formato Mensal ou Semanal"
          pageTitle="VISUALIZAÇÃO DA PREVISÃO FINANCEIRA"
          class="mb-2"
        />

        <div class="flex ai-c fgap2">
          <button
            type="button"
            class="toggle-calendar-type-btn dark"
            @click.prevent="modalExcelOpen = true"
          >
            EXPORTAR PARA EXCEL
          </button>
        </div>
      </div>

      <gs-calendar
        :week="isWeek"
        :content="filteredCalendarContent"
        @change="({ year, month, endDay }) => getProcessesMonth(year, month, endDay)"
        @changeWeek="({ start, end }) => getProcessWeek(start, end)"
      >
        <template #header="{ data }">
          <div class="flex ai-c jc-sb fgap2 mb-2">
            <div style="flex: 0 1 30%; display: flex; align-items: center; gap: 1rem">
              <drop-down contentPosition="left" label="Filtros" content-width="auto">
                <template #content>
                  <div class="calendar-filters">
                    <div class="calendar-filter-col">
                      <h5>Visualização</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Semanal
                            <input
                              type="radio"
                              name="calendarView"
                              id="calendarView"
                              :value="true"
                              v-model="isWeek"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Mensal
                            <input
                              type="radio"
                              name="calendarView"
                              id="calendarView"
                              :value="false"
                              v-model="isWeek"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>

                    <div class="calendar-filter-col">
                      <h5>Tipo</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Numerários
                            <input
                              type="checkbox"
                              name="numerarios"
                              id="numerarios"
                              value="numerarios"
                              v-model="calendarFilters"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            Invoices
                            <input
                              type="checkbox"
                              name="invoices"
                              id="invoices"
                              value="invoices"
                              v-model="calendarFilters"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>

                    <div class="calendar-filter-col">
                      <h5>Status Numerário</h5>
                      <ul class="calendar-views-list">
                        <li>
                          <label>
                            Pago
                            <input
                              type="checkbox"
                              name="pago"
                              id="pago"
                              value="pago"
                              v-model="calendarStatus"
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            À Pagar
                            <input
                              type="checkbox"
                              name="a_pagar"
                              id="a_pagar"
                              value="pagar"
                              v-model="calendarStatus"
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </drop-down>
              <button
                type="button"
                class="btn-refresh"
                @click.prevent="refresh(data)"
              >
                <RefreshIcon :size="24" color="#104375" />
              </button>
            </div>

            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-if="!data.week">
              <button class="prev-month" @click.prevent="data.prevMonth()">
                <ChevronLeftIcon />
              </button>

              <h5 class="text-primary text-center text-md refresh" style="width: 200px" @click.prevent="refresh(data)">
                <span class="text-medium">{{ data.months[data.month] }}</span>
                {{ data.year }}
              </h5>

              <button class="next-month" @click.prevent="data.nextMonth()">
                <ChevronRightIcon />
              </button>
            </div>

            <div style="flex: 0 1 40%" class="flex ai-c jc-c" v-else>
              <button class="prev-month" @click.prevent="data.prevWeek()">
                <ChevronLeftIcon />
              </button>

              <div class="refresh" @click.prevent="refresh(data)">
                <p class="text-center text-primary text-md ph-2">
                  <span class="text-bold">
                    {{ data.weekData.start.getDate() }}
                    {{ months[data.weekData.start.getMonth()] }}
                    {{ String(data.weekData.start.getFullYear()).substring(2) }}
                  </span>
                  <span>Até</span>
                  <span class="text-bold">
                    {{ data.weekData.end.getDate() }}
                    {{ months[data.weekData.end.getMonth()] }}
                    {{ String(data.weekData.end.getFullYear()).substring(2) }}
                  </span>
                </p>
              </div>

              <button class="next-month" @click.prevent="data.nextWeek()">
                <ChevronRightIcon />
              </button>
            </div>

            <div style="flex: 0 1 30%" class="text-sm text-right">
              <p class="text-light">
                Total de
                <span class="text-bold"> {{ getResultsCount }} itens </span>
                no período
              </p>

              <p class="text-light">
                Valor Total Previsto de
                <span class="text-bold">{{ getResultsTotal }}</span>
              </p>
            </div>
          </div>
        </template>

        <template #item="{ past, future, active, content, index, date }">
          <gs-calendar-day-content
            :ref="index"
            :data="content"
            :past="past"
            :future="future"
            :active="active"
            :grouped="!isWeek"
            :date="date"
            @action="(item) => calendarItemClick(item, date)"
            @innerAction="(item) => openProcess(item)"
            @downloadAction="(item) => downloadDocument(item)"
          />
        </template>

        <template #itemFooter="{ content, overview }">
          <div v-if="content && !overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">TOTAL</p>
            <p class="text-primary text-bold">{{ getWeekTotal(content) }}</p>
          </div>

          <div v-if="!content && !overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">TOTAL</p>
            <p class="text-primary text-bold">R$ 0,00</p>
          </div>

          <div v-if="content && overview" class="text-right pv-2 ph-1">
            <p class="text-primary text-sm">Valor previsto da semana</p>
            <p class="text-primary text-bold">{{ getWeekTotal(content) }}</p>
          </div>
        </template>

        <template #week="{ content }">
          <div
            v-if="content"
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-primary">TOTAL</p>
            <p class="text-primary text-bold mb-1">
              {{ getWeekTotal(content) }}
            </p>
            <p class="text-primary text-sm">Soma total de todos os processos na semana.</p>
          </div>
          <div
            v-else
            class="text-right flex fd-c"
            :class="{
              'jc-c': !isWeek,
              'jc-fs': isWeek,
            }"
            style="height: 100%"
          >
            <p class="text-primary">TOTAL</p>
            <p class="text-primary text-bold mb-1">R$ 0,00</p>
            <p class="text-primary text-sm">Soma total de todos os processos na semana.</p>
          </div>
        </template>

        <template #footer>
          <div class="flex jc-fe" v-if="!isWeek">
            <gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-accent text-bold">Valor Previsto no mês</p>
              </div>
            </gs-calendar-item>

            <gs-calendar-item day="" noBorder :past="false" otherMonth>
              <div class="text-right flex fd-c jc-c" style="height: 100%">
                <p class="text-primary">TOTAL</p>
                <p class="text-primary text-bold mb-1">
                  {{ getResultsTotal }}
                </p>

                <p class="text-primary text-sm">Soma total de todos os processos do mês.</p>
              </div>
            </gs-calendar-item>
          </div>
        </template>
      </gs-calendar>
    </div>

    <transition name="calendar">
      <div @click.self.prevent="closeOverlay" class="modal-calendar" v-if="modalCalendarOpen">
        <div class="modal-calendar-container">
          <div class="modal-calendar-header">
            <div>
              <p class="text-sm text-natural text-normal">
                Dia: {{ selectedDayNumber }} / {{ selectedDay.length }} lançamentos
              </p>

              <h4 class="text-primary text-bold mb-1">Total: {{ getWeekTotal(selectedDay) }}</h4>
            </div>

            <button @click.prevent="closeOverlay" class="close-modal-calendar">X</button>
          </div>

          <div class="modal-calendar-content">
            <gs-calendar-day-content
              :date="selectedDate"
              :data="selectedDay"
              :grouped="false"
              @innerAction="(item) => openProcess(item)"
            />
          </div>
        </div>
      </div>
    </transition>

    <ModalProcess
      :data="modalData"
      :handler="modalProcessOpen"
      @request-close="closeProcessModal"
    />

    <Modal :handler="modalExcelOpen" use-actions @request-close="modalExcelOpen = false">
      <template #head> Configurações da exportação </template>

      <template #content>
        <div class="calendar-filters" style="min-width: unset; max-width: 900px; width: 100%">
          <div class="calendar-filter-col">
            <h5>Datas</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  <span style="width: 80px">Início</span>
                  <DatePicker v-model="excelFilters.startDate" />
                </label>
              </li>
              <li>
                <label>
                  <span style="width: 80px">Fim</span>
                  <DatePicker v-model="excelFilters.endDate" />
                </label>
              </li>
            </ul>
          </div>

          <div class="calendar-filter-col">
            <h5>Tipo</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Numerários
                  <input
                    type="checkbox"
                    name="numerarios"
                    id="numerarios"
                    value="numerarios"
                    v-model="excelFilters.type"
                  />
                </label>
              </li>
              <li>
                <label>
                  Invoices
                  <input
                    type="checkbox"
                    name="invoices"
                    id="invoices"
                    value="invoices"
                    v-model="excelFilters.type"
                  />
                </label>
              </li>
            </ul>
          </div>

          <div class="calendar-filter-col">
            <h5>Status Numerário</h5>
            <ul class="calendar-views-list">
              <li>
                <label>
                  Pago
                  <input
                    type="checkbox"
                    name="pago"
                    id="pago"
                    value="pago"
                    v-model="excelFilters.status"
                  />
                </label>
              </li>
              <li>
                <label>
                  À Pagar
                  <input
                    type="checkbox"
                    name="a_pagar"
                    id="a_pagar"
                    value="pagar"
                    v-model="excelFilters.status"
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template #confirm-button>
        <download-excel
          :fetch="generateExcel"
          :fields="fieldsExcel"
          class="toggle-calendar-type-btn dark"
          name="calendario-financeiro.xls"
          style="white-space: nowrap"
        >
          EXPORTAR
        </download-excel>
      </template>
    </Modal>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import GsCalendar from '@/components/GsCalendar.vue';
import ChevronLeftIcon from '@/components/Icons/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/components/Icons/ChevronRightIcon.vue';
import RefreshIcon from '@/components/Icons/Refresh.vue';

import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import GsCalendarDayContent from '@/components/GsCalendarDayContent.vue';
import GsCalendarItem from '@/components/GsCalendarItem.vue';
import { CalendarColumnsExcel } from '@/utils/ProcessesReportColumnsExcel';
import ModalProcess from '@/components/ModalProcess.vue';
import moment from 'moment/moment';
import Modal from '@/components/Modal.vue';
import DatePicker from '@/components/DataInput/DatePicker.vue';

import DropDown from '../components/DropDown.vue';

export default {
  name: 'CalendarView',
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
  components: {
    Modal,
    DropDown,
    GsCalendarItem,
    GsCalendarDayContent,
    ChevronRightIcon,
    ChevronLeftIcon,
    RefreshIcon,
    GsCalendar,
    PageTitle,
    ModalProcess,
    DatePicker,
  },
  data() {
    return {
      modalExcelOpen: false,
      modalCalendarOpen: false,
      fieldsExcel: CalendarColumnsExcel,
      excelFilters: {
        startDate: null,
        endDate: null,
        type: ['numerarios', 'invoices'],
        status: ['pago', 'pagar'],
      },
      modalProcessOpen: false,
      modalData: {},
      overlayProps: {
        left: 0,
        width: 0,
      },
      selectedDay: [],
      selectedDayNumber: null,
      selectedDate: null,
      selectedProcess: {},
      calendarContent: {},
      calendarFilters: ['numerarios', 'invoices'],
      calendarStatus: ['pago', 'pagar'],
      isWeek: false,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['getProcessDocuments', 'getProcess']),
    async openProcess(item) {
      const ok = await this.getProcess({ identification: item.id });
      if (ok) {
        this.modalData = this.process;
        this.modalData.documents = await this.getProcessDocuments(item.id);
        this.modalProcessOpen = true;
      }
    },
    closeProcessModal() {
      this.modalProcessOpen = false;
      this.modalData = null;
    },
    async getProcessesMonth(year, month) {
      this.closeOverlay();

      if (this.user.companies) {
        await this.toggleLoading(true);

        const companiesIds = this.user.companies.map((c) => c.id).join(',');

        try {
          let response;

          if (!year || !month) {
            const date = new Date();
            const ld = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

            const imonth = String(date.getMonth() + 1).padStart(2, '0');

            response = await api.get(`/cash-calendar?customerIds=${companiesIds}&startDate=${date.getFullYear()}-${imonth}-01T00:00:00&endDate=${date.getFullYear()}-${imonth}-${ld}T23:59:59`);
          } else {
            const imonth = String(month).padStart(2, '0');
            const ld = new Date(year, month, 0).getDate();

            response = await api.get(`/cash-calendar?customerIds=${companiesIds}&startDate=${year}-${imonth}-01T00:00:00&endDate=${year}-${imonth}-${ld}T23:59:59`);
          }

          let newObj = {};

          for (let i = 0; i < Object.values(response.data.data).length; i += 1) {
            const item = Object.values(response.data.data)[i];
            newObj = this.checkCalendarItemDates(
              item,
              ['estimatedStepCashRequest', 'datesETA', 'invoice'],
              year,
              month,
              newObj,
            );
          }

          this.calendarContent = newObj;
        } catch (e) {
          this.$toast.error(e.message);
        } finally {
          await this.toggleLoading(false);
        }
      }
    },
    async getProcessWeek(start, end) {
      if (this.user.companies) {
        await this.toggleLoading(true);

        const companiesIds = this.user.companies.map((c) => c.id).join(',');

        try {
          let response;

          const date = new Date();
          const startD = new Date(date.setDate(date.getDate() - date.getDay()));
          const endD = new Date(date.setDate(date.getDate() - date.getDay() + 6));

          if (!start || !end) {
            response = await api.get(
              `/cash-calendar?customerIds=${companiesIds}&startDate=${startD.getFullYear()}-${String(
                startD.getMonth() + 1,
              ).padStart(2, '0')}-${String(startD.getDate()).padStart(
                2,
                '0',
              )}T00:00:00&endDate=${endD.getFullYear()}-${String(endD.getMonth() + 1).padStart(
                2,
                '0',
              )}-${String(endD.getDate()).padStart(2, '0')}T23:59:59`,
            );
          } else {
            response = await api.get(
              `/cash-calendar?customerIds=${companiesIds}&startDate=${start.getFullYear()}-${String(
                start.getMonth() + 1,
              ).padStart(2, '0')}-${String(start.getDate()).padStart(
                2,
                '0',
              )}T00:00:00&endDate=${end.getFullYear()}-${String(end.getMonth() + 1).padStart(
                2,
                '0',
              )}-${String(end.getDate()).padStart(2, '0')}T23:59:59`,
            );
          }

          let newObj = {};

          for (let i = 0; i < Object.values(response.data.data).length; i += 1) {
            const item = Object.values(response.data.data)[i];
            if (!start || !end) {
              newObj = this.checkCalendarItemDates(
                item,
                ['estimatedStepCashRequest', 'datesETA', 'invoice'],
                startD.getFullYear(),
                startD.getMonth() + 1,
                newObj,
              );
              newObj = this.checkCalendarItemDates(
                item,
                ['estimatedStepCashRequest', 'datesETA', 'invoice'],
                endD.getFullYear(),
                endD.getMonth() + 1,
                newObj,
              );
            } else {
              newObj = this.checkCalendarItemDates(
                item,
                ['estimatedStepCashRequest', 'datesETA', 'invoice'],
                start.getFullYear(),
                start.getMonth() + 1,
                newObj,
              );
              if (start.getMonth() !== end.getMonth()) {
                newObj = this.checkCalendarItemDates(
                  item,
                  ['estimatedStepCashRequest', 'datesETA', 'invoice'],
                  end.getFullYear(),
                  end.getMonth() + 1,
                  newObj,
                );
              }
            }
          }

          this.calendarContent = newObj;
        } catch (e) {
          this.$toast.error(e.message);
        } finally {
          await this.toggleLoading(false);
        }
      }
    },
    checkCalendarItemDates(item, fields, year, month, obj) {
      const formattedObj = { ...obj };
      const clonedItems = {};

      fields.forEach((field) => {
        Object.assign(clonedItems, {
          [field]: { ...item },
        });
      });

      const iYear = String(new Date().getFullYear());
      const iMonth = String(new Date().getMonth() + 1).padStart(2, '0');

      fields.forEach((field) => {
        let internalField = field;
        let dateField = field;

        if (internalField !== 'invoice' && clonedItems[internalField][internalField]) {
          if (internalField === 'estimatedStepCashRequest' && clonedItems[internalField].datesETA) {
            return;
          }

          if (internalField === 'datesETA' && clonedItems[internalField].stepCashRequestEstimatedValue <= 0) {
            internalField = 'estimatedStepCashRequest';
            dateField = 'datesETA';
          }

          const itemDate = new Date(clonedItems[internalField][dateField]);
          const itemYear = String(itemDate.getFullYear());
          const itemMonth = String(itemDate.getMonth() + 1).padStart(2, '0');
          const itemDay = String(itemDate.getDate()).padStart(2, '0');

          if (!year || !month) {
            if (itemYear === iYear && itemMonth === iMonth) {
              if (formattedObj[`${itemYear}-${itemMonth}-${itemDay}`]) {
                Object.assign(clonedItems[internalField], {
                  itemType: internalField,
                  itemDate,
                });
                formattedObj[`${itemYear}-${itemMonth}-${itemDay}`].push(
                  clonedItems[internalField],
                );
              } else {
                Object.assign(clonedItems[internalField], {
                  itemType: internalField,
                  itemDate,
                });
                formattedObj[`${itemYear}-${itemMonth}-${itemDay}`] = [clonedItems[internalField]];
              }
            }
          } else if (itemYear === String(year) && itemMonth === String(month).padStart(2, '0')) {
            if (formattedObj[`${itemYear}-${itemMonth}-${itemDay}`]) {
              Object.assign(clonedItems[internalField], { itemType: internalField, itemDate });
              formattedObj[`${itemYear}-${itemMonth}-${itemDay}`].push(clonedItems[internalField]);
            } else {
              Object.assign(clonedItems[internalField], { itemType: internalField, itemDate });
              formattedObj[`${itemYear}-${itemMonth}-${itemDay}`] = [clonedItems[internalField]];
            }
          }
        }

        if (internalField === 'invoice') {
          if (clonedItems[field].invoices && clonedItems[internalField].invoices.length > 0) {
            for (let i = 0; i < clonedItems[internalField].invoices.length; i += 1) {
              const invoice = { ...clonedItems[internalField].invoices[i] };

              if (invoice.installments && invoice.installments.length > 0) {
                for (let j = 0; j < invoice.installments.length; j += 1) {
                  const parcel = invoice.installments[j];
                  const parcelDate = new Date(parcel.dueDate);
                  const parcelYear = String(parcelDate.getFullYear());
                  const parcelMonth = String(parcelDate.getMonth() + 1).padStart(2, '0');
                  const parcelDay = String(parcelDate.getDate()).padStart(2, '0');

                  const innerItem = { ...clonedItems[internalField] };

                  Object.assign(innerItem, {
                    itemType: 'invoice',
                    itemDate: parcelDate,
                    itemInvoice: invoice.id,
                  });

                  if (!year || !month) {
                    if (iYear === parcelYear && iMonth === parcelMonth) {
                      if (formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`]) {
                        formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`].push(innerItem);
                      } else {
                        formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`] = [innerItem];
                      }
                    }
                  } else if (
                    String(year) === parcelYear
                    && String(month).padStart(2, '0') === parcelMonth
                  ) {
                    if (formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`]) {
                      formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`].push(innerItem);
                    } else {
                      formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`] = [innerItem];
                    }
                  }
                }
              }
            }
          }
        }
      });

      return formattedObj;
    },
    checkCalendarItemDatesExcel(item, fields, obj) {
      const formattedObj = { ...obj };
      const clonedItems = {};

      fields.forEach((field) => {
        Object.assign(clonedItems, {
          [field]: { ...item },
        });
      });

      fields.forEach((field) => {
        let internalField = field;

        if (internalField !== 'invoice' && clonedItems[internalField][internalField]) {
          if (internalField === 'estimatedStepCashRequest' && clonedItems[internalField].datesETA) {
            return;
          }

          if (
            internalField === 'datesETA'
            && clonedItems[internalField].stepCashRequestEstimatedValue <= 0
          ) {
            internalField = 'estimatedStepCashRequest';
          }

          const itemDate = new Date(clonedItems[internalField][internalField]);
          const itemYear = String(itemDate.getFullYear());
          const itemMonth = String(itemDate.getMonth() + 1).padStart(2, '0');
          const itemDay = String(itemDate.getDate()).padStart(2, '0');

          if (formattedObj[`${itemYear}-${itemMonth}-${itemDay}`]) {
            Object.assign(clonedItems[internalField], {
              itemType: internalField,
              itemDate,
            });
            formattedObj[`${itemYear}-${itemMonth}-${itemDay}`].push(clonedItems[internalField]);
          } else {
            Object.assign(clonedItems[internalField], {
              itemType: internalField,
              itemDate,
            });
            formattedObj[`${itemYear}-${itemMonth}-${itemDay}`] = [clonedItems[internalField]];
          }
        }

        if (internalField === 'invoice') {
          if (clonedItems[field].invoices && clonedItems[internalField].invoices.length > 0) {
            for (let i = 0; i < clonedItems[internalField].invoices.length; i += 1) {
              const invoice = { ...clonedItems[internalField].invoices[i] };

              if (invoice.installments && invoice.installments.length > 0) {
                for (let j = 0; j < invoice.installments.length; j += 1) {
                  const parcel = invoice.installments[j];
                  const parcelDate = new Date(parcel.dueDate);
                  const parcelYear = String(parcelDate.getFullYear());
                  const parcelMonth = String(parcelDate.getMonth() + 1).padStart(2, '0');
                  const parcelDay = String(parcelDate.getDate()).padStart(2, '0');

                  const innerItem = { ...clonedItems[internalField] };

                  Object.assign(innerItem, {
                    itemType: 'invoice',
                    itemDate: parcelDate,
                    itemInvoice: invoice.id,
                  });

                  if (formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`]) {
                    formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`].push(innerItem);
                  } else {
                    formattedObj[`${parcelYear}-${parcelMonth}-${parcelDay}`] = [innerItem];
                  }
                }
              }
            }
          }
        }
      });

      return formattedObj;
    },
    getWeekTotal(content) {
      const result = content.reduce((acc, cur) => {
        let total = acc;

        if (cur.itemType === 'datesETA') {
          if (!cur.stepCashRequestEstimatedValue) {
            total += cur.estimatedStepCashRequestEstimatedValue;
          } else {
            total += cur.stepCashRequestEstimatedValue;
          }
        }

        if (cur.itemType === 'estimatedStepCashRequest') {
          total += cur.estimatedStepCashRequestEstimatedValue;
        }

        if (cur.itemType === 'invoice') {
          for (let i = 0; i < cur.invoices.length; i += 1) {
            const invoice = cur.invoices[i];

            if (cur.itemInvoice === invoice.id) {
              if (invoice.installments && invoice.installments.length > 0) {
                for (let j = 0; j < invoice.installments.length; j += 1) {
                  const parcel = invoice.installments[j];
                  const itemDate = `${cur.itemDate.getFullYear()}-${String(cur.itemDate.getMonth() + 1).padStart(2, '0')}-${String(cur.itemDate.getDate()).padStart(2, '0')}`;

                  if (parcel.dueDate.split('T')[0] === itemDate) {
                    total += parcel.valueInBrl;
                  }
                }
              }
            }
          }
        }

        return total;
      }, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    calendarItemClick(data, date) {
      if (!this.isWeek) {
        this.selectedDayNumber = String(date.getDate()).padStart(2, '0');
        this.selectedDate = date;
        this.selectedDay = data;
        this.modalCalendarOpen = true;
      }
    },
    closeOverlay() {
      this.modalProcessOpen = false;
      this.selectedDayNumber = null;
      this.selectedDay = [];
      this.modalCalendarOpen = false;
    },
    // toggleCalendarView() {
    //   this.isWeek = !this.isWeek;
    //   this.closeOverlay();
    // },
    checkEscape(e) {
      if (e.keyCode === 27) {
        this.closeOverlay();
      }
    },
    async finishDownloadDocument(process) {
      await this.toggleLoading(true);
      const { document, logs } = process.documents[process.documents.length - 1];

      try {
        const response = await api.get(
          `/api/public/customer/processes/${process.id}/documents/${document.id}/download`,
          {
            responseType: 'blob',
          },
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = window.document.createElement('a');
        link.href = url;
        link.setAttribute('download', document.originalFilename);
        window.document.body.appendChild(link);
        link.click();

        logs.push({
          entityName: 'Document',
          createdBy: 'Nome do Usuário',
          type: 'DOWNLOAD',
          created: moment(new Date()).format('YYYY-MM-DD\\THH:mm:ss.SSS'),
        });
      } catch (err) {
        this.$toast.error('Não foi possível fazer o download do arquivo!');
      } finally {
        await this.toggleLoading(false);
      }
    },
    async downloadDocument(process) {
      const { logs } = process.documents[process.documents.length - 1];

      if (logs.length > 0) {
        const logDate = moment(new Date(logs[logs.length - 1].created)).format(
          'DD/MM/YYYY à\\s HH:mm',
        );
        const { isConfirmed } = await this.$swal.fire({
          title: 'Este numerário já foi baixado anteriormente!',
          text: `O Usuário ${logs[logs.length - 1].createdBy} baixou o arquivo em ${logDate}`,
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Baixar mesmo assim',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: 'var(--danger-color)',
          showCloseButton: true,
        });

        if (isConfirmed) {
          await this.finishDownloadDocument(process);
        }
        return;
      }

      await this.finishDownloadDocument(process);
    },
    async generateExcel() {
      await this.toggleLoading(true);

      if (
        !this.excelFilters.startDate
        || this.excelFilters.startDate == null
        || this.excelFilters.startDate === ''
      ) {
        await this.$toast.error('A data de início e de fim devem estar preenchidas!');
        await this.toggleLoading(false);
        return false;
      }

      if (
        !this.excelFilters.endDate
        || this.excelFilters.endDate == null
        || this.excelFilters.endDate === ''
      ) {
        await this.$toast.error('A data de início e de fim devem estar preenchidas!');
        await this.toggleLoading(false);
        return false;
      }

      const companiesIds = this.user.companies.map((c) => c.id).join(',');

      try {
        const startDateReq = `${this.excelFilters.startDate.split('T')[0]}T00:00:00`;
        const endDateReq = `${this.excelFilters.endDate.split('T')[0]}T23:59:59`;

        const response = await api.get(
          `/cash-calendar?customerIds=${companiesIds}&startDate=${startDateReq}&endDate=${endDateReq}`,
        );

        let newObj = {};

        for (let i = 0; i < Object.values(response.data.data).length; i += 1) {
          const item = Object.values(response.data.data)[i];
          newObj = this.checkCalendarItemDatesExcel(
            item,
            ['estimatedStepCashRequest', 'datesETA', 'invoice'],
            newObj,
          );
        }

        return this.formattedProcessToExcel(this.filteredCalendarContentExcel(newObj));
      } catch (e) {
        await this.$toast.error(e.response.data.message);
      } finally {
        await this.toggleLoading(false);
      }

      return this.formattedProcessToExcel({});
    },
    formattedProcessToExcel(data) {
      const content = [];
      for (let i = 0; i < Object.keys(data).length; i += 1) {
        const itemI = Object.values(data)[i];
        for (let j = 0; j < itemI.length; j += 1) {
          content.push(itemI[j]);
        }
      }

      return content.map((item) => {
        const {
          invoices,
          estimatedStepCashRequestEstimatedValue,
          stepCashRequestEstimatedValue,
          ...inItem
        } = item;

        if (inItem.itemType === 'datesETA') {
          return {
            ...inItem,
            estimatedStepCashRequestEstimatedValue: null,
            stepCashRequestEstimatedValue,
            type: 'Numerário',
          };
        }

        if (inItem.itemType === 'estimatedStepCashRequest') {
          return {
            ...inItem,
            estimatedStepCashRequestEstimatedValue,
            stepCashRequestEstimatedValue: null,
            type: 'Numerário Previsto',
          };
        }

        if (inItem.itemType === 'invoice') {
          const itemDate = `${inItem.itemDate.getFullYear()}-${String(
            inItem.itemDate.getMonth() + 1,
          ).padStart(2, '0')}-${String(inItem.itemDate.getDate()).padStart(2, '0')}`;
          const inInvoice = invoices.find((inv) => inv.id === item.itemInvoice);
          const installment = inInvoice.installments.find(
            (ins) => ins.dueDate.split('T')[0] === itemDate,
          );

          return {
            ...inItem,
            estimatedStepCashRequestEstimatedValue: null,
            stepCashRequestEstimatedValue: null,
            type: 'Parcela da Invoice',
            invoice: inInvoice.invoiceNumber,
            installmentInBrl: installment.valueInBrl,
            currency: inInvoice.currency,
            installment: installment.value,
          };
        }

        return {};
      });
    },
    filteredCalendarContentExcel(data) {
      const res = {};

      const dates = Object.keys(data);

      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        res[date] = [];

        for (let j = 0; j < data[date].length; j += 1) {
          const item = data[date][j];

          if (this.excelFilters.type.includes('invoices') && item.itemType === 'invoice') {
            res[date].push(item);
          }

          if (this.excelFilters.type.includes('numerarios') && item.itemType !== 'invoice') {
            res[date].push(item);
          }
        }
      }

      if (this.excelFilters.status.length === 2) {
        return res;
      }

      if (this.excelFilters.status.length === 0) {
        return [];
      }

      const resDates = Object.keys(res);

      for (let i = 0; i < resDates.length; i += 1) {
        const date = resDates[i];

        if (this.excelFilters.status.includes('pago')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => {
              if (item.itemType === 'invoice') {
                return item.invoices.some((invoice) => invoice.invoiceDue !== null);
              }
              return item.cashRequestDone === true;
            });
          }
        }

        if (this.excelFilters.status.includes('pagar')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => {
              if (item.itemType === 'invoice') {
                return item.invoices.some((invoice) => invoice.invoiceDue === null);
              }
              return item.cashRequestDone !== true;
            });
          }
        }
      }

      return res;
    },
    refresh(data) {
      if (data.week) {
        this.getProcessWeek(data.weekData.start, data.weekData.end);
      } else {
        this.getProcessesMonth(data.year, data.month + 1);
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('process', ['process']),
    filteredCalendarContent() {
      const res = {};

      const dates = Object.keys(this.calendarContent);

      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        res[date] = [];

        for (let j = 0; j < this.calendarContent[date].length; j += 1) {
          const item = this.calendarContent[date][j];

          if (this.calendarFilters.includes('invoices') && item.itemType === 'invoice') {
            res[date].push(item);
          }

          if (this.calendarFilters.includes('numerarios') && item.itemType !== 'invoice') {
            res[date].push(item);
          }
        }
      }

      if (this.calendarStatus.length === 2) {
        return res;
      }

      if (this.calendarStatus.length === 0) {
        return [];
      }

      const resDates = Object.keys(res);

      for (let i = 0; i < resDates.length; i += 1) {
        const date = resDates[i];

        if (this.calendarStatus.includes('pago')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => {
              if (item.itemType === 'invoice') {
                return item.invoices.some((invoice) => invoice.invoiceDue !== null);
              }
              return item.cashRequestDone === true;
            });
          }
        }

        if (this.calendarStatus.includes('pagar')) {
          if (res[date] && res[date].length > 0) {
            res[date] = res[date].filter((item) => {
              if (item.itemType === 'invoice') {
                return item.invoices.some((invoice) => invoice.invoiceDue === null);
              }
              return item.cashRequestDone !== true;
            });
          }
        }
      }

      return res;
    },
    getResultsTotal() {
      const content = [];
      for (let i = 0; i < Object.keys(this.filteredCalendarContent).length; i += 1) {
        const itemI = Object.values(this.filteredCalendarContent)[i];
        for (let j = 0; j < itemI.length; j += 1) {
          content.push(itemI[j]);
        }
      }
      const result = content.reduce((acc, cur) => {
        let total = acc;

        if (cur.itemType === 'datesETA') {
          if (!cur.stepCashRequestEstimatedValue) {
            total += cur.estimatedStepCashRequestEstimatedValue;
          } else {
            total += cur.stepCashRequestEstimatedValue;
          }
        }

        if (cur.itemType === 'estimatedStepCashRequest') {
          total += cur.estimatedStepCashRequestEstimatedValue;
        }

        if (cur.itemType === 'invoice') {
          for (let i = 0; i < cur.invoices.length; i += 1) {
            const invoice = cur.invoices[i];

            if (cur.itemInvoice === invoice.id) {
              if (invoice.installments && invoice.installments.length > 0) {
                for (let j = 0; j < invoice.installments.length; j += 1) {
                  const parcel = invoice.installments[j];
                  const itemDate = `${cur.itemDate.getFullYear()}-${String(cur.itemDate.getMonth() + 1).padStart(2, '0')}-${String(cur.itemDate.getDate()).padStart(2, '0')}`;

                  if (parcel.dueDate.split('T')[0] === itemDate) {
                    total += parcel.valueInBrl;
                  }
                }
              }
            }
          }
        }

        return total;
      }, 0);
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(result);
    },
    getResultsCount() {
      let res = 0;

      for (let i = 0; i < Object.keys(this.calendarContent).length; i += 1) {
        res += Object.values(this.calendarContent)[i].length;
      }

      return res;
    },
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
  },
  async mounted() {
    window.addEventListener('keyup', this.checkEscape);
    if (this.user.companies) {
      await this.getProcessesMonth();
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.checkEscape);
  },
  watch: {
    user() {
      this.getProcessesMonth();
    },
  },
};
</script>

<style scoped>
.next-month,
.prev-month {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--accent-color);
  color: var(--primary-color);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  transition: var(--all-transition);
}

.refresh{
  cursor: pointer
}

.next-month:hover,
.prev-month:hover {
  background: var(--accent-color);
}

.btn-refresh{
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--accent-color);
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  transition: var(--all-transition);
}

.btn-refresh:hover {
  background: var(--accent-color);
}

.toggle-calendar-type-btn {
  padding: 1rem 1.5rem;
  font-family: Poppins, sans-serif;
  border-radius: 100px;
  background-color: #f4f7f9;
  color: var(--primary-color);
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: var(--all-transition);
  text-transform: uppercase;
}

.toggle-calendar-type-btn:hover {
  background-color: var(--primary-color);
  color: #f4f7f9;
}

.calendar-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.calendar-modal {
  position: absolute;
  top: 0;
  bottom: 1rem;
  z-index: 5;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0.75rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style>
.calendar-enter-active,
.calendar-leave-active {
  transition: var(--all-transition);
}

.calendar-enter,
.calendar-leave-to {
  opacity: 0;
}

.calendar-enter-to,
.calendar-leave {
  opacity: 1;
}

.calendar-views-list {
  border-radius: 10px;
  border: var(--input-border);
}

.calendar-views-list label {
  color: var(--primary-color);
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.25rem;
  height: 2.5rem;
  margin-bottom: 0;
}

.calendar-views-list li:not(:last-of-type) {
  border-bottom: var(--input-border);
}

.modal-calendar {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.modal-calendar-container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  max-width: 300px;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}

.modal-calendar-container.w-35 {
  max-width: 35%;
  width: 100%;
}

.modal-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.close-modal-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-bg-color);
  border: 0;
  cursor: pointer;
  font-family: var(--body-font-family), 'sans-serif';
  font-weight: 900;
  transition: all 0.2s ease-in-out 0s;
  color: var(--primary-color);
}

.close-modal-calendar:hover {
  background-color: var(--primary-color);
  color: white;
}

.calendar-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: 600px;
  overflow: hidden;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  background-color: white;
}

.calendar-filter-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.calendar-filter-col h5 {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-color);
}

.overlay-calendar-modal {
  position: absolute;
  inset: 0;
  z-index: 9999;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .calendar-filters {
    grid-template-columns: 1fr;
    min-width: unset;
  }
}
</style>
