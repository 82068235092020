<template>
  <div
    :class="[
      'info-card flex fd-c ai-c',
      `span-${columns}`,
      cursor ? 'pointer' : ''
    ]"
    @click="$emit('click')"
  >
    <div class="top-bar mb-2" :style="`background-color: ${topBarColor}`"></div>

    <div class="text-center mb-4">
      <number v-if="animated"
        class="big-info text-xxg"
        ref="number1"
        :from="0"
        :to="bigInfo"
        :duration="5"
        :delay="1"
        easing="Power1.easeOut" />
      <h4 v-else class="big-info text-xxg">{{ bigInfo }}</h4>
      <p class="small-info text-sm">{{ smallInfo }}</p>
    </div>

    <div class="text-center">
      <h4 class="info-title mb-1 text-bold">{{ title }}</h4>
      <p class="info-desc text-sm text-light">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    bigInfo: {
      type: [String, Number],
      default: '',
    },
    smallInfo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    topBar: {
      type: Boolean,
      default: true,
    },
    topBarColor: {
      type: String,
      default: '#104375',
    },
    animated: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .info-card {
    border: 1px solid #DEE2E6;
    border-radius: var(--medium-border-radius);
    padding: 0.75rem;
    background-color: var(--white-color);
  }
  .top-bar {
    width: 100%;
    height: 6px;
  }

  @media screen and (max-width: 1024px) {
    .info-card {
      grid-column: span 1;
    }
  }
</style>
