<template>
  <svg :width="size" :height="size" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5425 4.10156L10.7661 0.273438C10.5925 0.0976562 10.3572 0 10.1103 0H9.875V5H14.8125V4.76172C14.8125 4.51562 14.7161 4.27734 14.5425 4.10156ZM8.64062 5.3125V0H0.925781C0.412744 0 0 0.417969 0 0.9375V19.0625C0 19.582 0.412744 20 0.925781 20H13.8867C14.3998 20 14.8125 19.582 14.8125 19.0625V6.25H9.56641C9.05723 6.25 8.64062 5.82812 8.64062 5.3125ZM2.46875 2.8125C2.46875 2.63984 2.60685 2.5 2.77734 2.5H5.86328C6.03378 2.5 6.17188 2.63984 6.17188 2.8125V3.4375C6.17188 3.61016 6.03378 3.75 5.86328 3.75H2.77734C2.60685 3.75 2.46875 3.61016 2.46875 3.4375V2.8125ZM2.46875 5.9375V5.3125C2.46875 5.13984 2.60685 5 2.77734 5H5.86328C6.03378 5 6.17188 5.13984 6.17188 5.3125V5.9375C6.17188 6.11016 6.03378 6.25 5.86328 6.25H2.77734C2.60685 6.25 2.46875 6.11016 2.46875 5.9375ZM8.02344 16.2453V17.1875C8.02344 17.3602 7.88534 17.5 7.71484 17.5H7.09766C6.92716 17.5 6.78906 17.3602 6.78906 17.1875V16.2387C6.35356 16.216 5.93002 16.0621 5.57899 15.7953C5.42855 15.6809 5.42084 15.4527 5.557 15.3211L6.01025 14.8832C6.1171 14.7801 6.27603 14.7754 6.40101 14.8547C6.55029 14.9492 6.71963 15 6.89553 15H7.97985C8.23058 15 8.43502 14.7688 8.43502 14.4848C8.43502 14.2523 8.29577 14.0477 8.09673 13.9875L6.36089 13.4602C5.64379 13.2422 5.14271 12.5453 5.14271 11.7652C5.14271 10.8074 5.87755 10.0293 6.78868 10.0047V9.0625C6.78868 8.88984 6.92677 8.75 7.09727 8.75H7.71446C7.88496 8.75 8.02305 8.88984 8.02305 9.0625V10.0113C8.45856 10.034 8.8821 10.1875 9.23312 10.4547C9.38356 10.5691 9.39128 10.7973 9.25511 10.9289L8.80186 11.3668C8.69501 11.4699 8.53609 11.4746 8.41111 11.3953C8.26183 11.3004 8.09249 11.25 7.91659 11.25H6.83227C6.58153 11.25 6.37709 11.4812 6.37709 11.7652C6.37709 11.9977 6.51634 12.2023 6.71539 12.2625L8.45123 12.7898C9.16832 13.0078 9.6694 13.7047 9.6694 14.4848C9.6694 15.443 8.93456 16.2207 8.02344 16.2453Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'InvoiceIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
