<template>
  <div class="full-height flex">
    <div class="sidebar-cont relative">
      <Sidebar />
    </div>
    <div class="dashboard-container flex1 p-2 flex fd-c fgap2">
      <Header />
      <transition name="pages" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>

    <cookies-policy />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import CookiesPolicy from './CookiesPolicy.vue';

export default {
  name: 'Dashboard',
  components: {
    CookiesPolicy,
    Sidebar,
    Header,
  },
};
</script>

<style scoped>
  .dashboard-container {
    overflow: auto;
  }
  .sidebar-cont {
    width: 80px;
    background-color: var(--white-color);
  }
</style>
