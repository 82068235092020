<template>
  <div>
    <PageTitle
      :pageTitle="pageTitle"
      :breadcrumbName="breadcrumbName"
      class="mb-2"
    />

    <div class="tabs">
      <ul class="tab-list flex">
        <li class="tab-list-item">
          <a href="#" :class="activeTab === 'GERAL' ? 'active' : ''" @click.prevent="activeTab = 'GERAL'">GERAL</a>
        </li>
        <li class="tab-list-item" v-if="isBuhler">
          <a href="#" :class="activeTab === 'TRANSIT TIME' ? 'active' : ''" @click.prevent="activeTab = 'TRANSIT TIME'">TRANSIT TIME</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-item" v-if="activeTab === 'GERAL'">
          <home-general-tab />
        </div>

        <div class="tab-item" v-if="activeTab === 'TRANSIT TIME' && isBuhler">
          <home-transit-time-tab />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment/moment';
import isFullMonth from '@/utils/DateUtils';
/* eslint-disable */
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import HomeGeneralTab from '@/components/HomeGeneralTab.vue';
import HomeTransitTimeTab from '@/components/HomeTransitTimeTab.vue';

export default {
  name: 'Home',
  components: {
    HomeTransitTimeTab,
    HomeGeneralTab,
    // Components
    PageTitle,

  },
  data() {
    return {
      activeTab: 'GERAL',
      isBuhler: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['updateUser']),
    ...mapActions('process', ['getProcesses', 'getProcessDocuments']),
    ...mapActions(
      'analytics',
      [
        'getLeadtimesIndicators',
        'clearAllIndicators',
        'getDiRegistrationIndicators',
        'getStepsIndicators',
        'getProceduralMovimentsIndicatorsAtualPeriod',
        'getTimeStorageIndicators',
      ],
    ),
    async openHerculesModal() {
      if (this.herculesIndicator.length > 0) {
        this.toggleLoading(true);
        const response = await api.get(`/api/public/customer/analytics/getProcessesByIds?ids=${this.herculesIndicator.join(',')}`);
        this.herculesData = response.data.data;
        this.toggleLoading(false);

        this.herculesModalOpen = true;
      }
    },
    async filtrarIndicadores(start, end) {
      if (this.user.companies !== undefined) {
        this.getIndicadores(this.user, start, end);
      } else {
        const userResponse = await api.get('/credential');
        this.getIndicadores(userResponse.data.data, start, end);
      }

      if (this.isHercules) {
        this.getHerculesIndicator(start, end);
      }
    },
    async openProcess(process) {
      this.toggleLoading(true);
      this.modalData = process;
      this.modalData.documents = await this.getProcessDocuments(process.id);
      this.toggleLoading(false);
      this.modalProcessOpen = true;
    },
    async getIndicadores(user, start, end) {
      const companiesId = user.person
        .companies
        .filter((a) => a.customer)
        .map((a) => a.id)
        .join();

      this.startDate = start !== null ? `${moment(start, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00` : `${moment(this.startDate, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00`;
      this.endDate = end !== null ? `${moment(end, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59` : `${moment(this.endDate, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59`;

      const valorStart = start !== null ? start : this.startDate;
      const valorEnd = end !== null ? end : this.endDate;

      // Seta os valores nos inputs de data
      this.startDateInput = valorStart;
      this.endDateInput = valorEnd;

      this.calcularDatasVariacao(valorStart, valorEnd);

      this.toggleLoading(true);
      await this.getStepsIndicators({ companiesId });
      await this.getLeadtimesIndicators({ startDate: this.startDate, endDate: this.endDate, companiesId });
      await this.getDiRegistrationIndicators({ startDate: this.startDate, endDate: this.endDate, companiesId });
      await this.getProceduralMovimentsIndicatorsAtualPeriod({ startDate: this.startDate, endDate: this.endDate, companiesId });
      await this.getTimeStorageIndicators({ startDate: this.startDate, endDate: this.endDate, companiesId });
      this.$toast.success('Indicadores gerados com sucesso!');
      this.toggleLoading(false);
    },
    calcularDatasVariacao(startDate, endDate) {
      const startMoment = moment(startDate, 'DD/MM/YYYY');
      const endMoment = moment(endDate, 'DD/MM/YYYY');

      if (isFullMonth(startMoment, endMoment)) {
        this.variacaoAberturaStartDate = startMoment.subtract('months', 1).format('DD/MM/YYYY');
        this.variacaoAberturaEndDate = endMoment.subtract('months', 1).format('DD/MM/YYYY');
      } else {
        const difference = endMoment.diff(startMoment, 'days');

        this.variacaoAberturaEndDate = startMoment.subtract('days', 1).format('DD/MM/YYYY');
        this.variacaoAberturaStartDate = startMoment.subtract('days', difference).format('DD/MM/YYYY');
      }
    },
    async requestIndicator() {
      this.toggleLoading(true);
      const response = await api.post('/api/public/customer/analytics/enviarEmailSolicitacaoNovoIndicador', { message: this.indicatorMessage });

      if (response) {
        this.toggleLoading(false);
        this.indicatorModalOpen = false;
        this.indicatorMessage = '';
        this.$toast.success('Sua solicitação foi enviada com sucesso!');
      }
    },
    closeIndicatorModal() {
      this.indicatorModalOpen = false;
      this.indicatorMessage = '';
    },
    async getHerculesIndicator(startDate, endDate) {
      const firstDate = startDate != null ? `${moment(startDate, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00` : this.startDate;
      const secondDate = endDate != null ? `${moment(endDate, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59` : this.endDate;

      const herculesIndicatorResponse = await api.get(`/api/public/customer/analytics/getHerculesEtdIndicator?firstDate=${firstDate}&secondDate=${secondDate}`);
      this.herculesIndicator = herculesIndicatorResponse.data.data;
    },
  },
  mounted() {
    if (this.user.companies != undefined && this.user.companies.find((comp) => comp.id === 154)) {
      this.isBuhler = true;
    }
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
  watch: {
    user: {
      deep: true,
      handler(value) {
        if (value.person !== undefined && value.person.companies.length > 0) {
          this.isHercules = value.person.companies.filter((a) => a.federalID === '07.442.711/0001-65' || a.id === 3).length > 0;
        }
      },
    },
  },
};
</script>

<style scoped>

  .tab-list {
    margin-bottom: -10px;
  }

  .tab-list a {
    padding-bottom: 25px !important;
  }

  .card-columns {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }

  .chart {
    max-height: 420px;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    .card-columns {
      grid-template-columns: 1fr;
    }
    .info-cards-title {
      grid-column: span 1;
    }
  }

  .info-card-custom {
    border: 1px solid #DEE2E6;
    border-radius: var(--medium-border-radius);
    padding: 0.75rem;
    background-color: var(--white-color);
  }
  .top-bar-custom {
    width: 100%;
    height: 6px;
  }

  @media screen and (max-width: 1024px) {
    .info-card-custom {
      grid-column: span 1;
    }
  }
</style>
