<template>
  <div>
    <transition mode="out-in" name="pages">
      <router-view></router-view>
    </transition>
    <Loading v-if="loading"/>
    <cookie-policy/>
  </div>
</template>

<script>
import '@/assets/css/main.css';

import { mapState, mapActions } from 'vuex';
import api from '@/services/api';

import Loading from '@/components/Loading.vue';
import CookiePolicy from '@/components/CookiesPolicy.vue';

export default {
  components: {
    CookiePolicy,
    Loading,
  },
  methods: {
    ...mapActions('auth', ['updateUser']),
  },
  computed: {
    ...mapState(['loading']),
  },
  async created() {
    if (localStorage.getItem('@GSCMX-PC:access_token')) {
      const userResponse = await api.get('/credential');
      this.updateUser(userResponse.data.data);
    }
  },
};
</script>

<style>
</style>
