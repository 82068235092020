<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.80966 13.9336C2.11889 13.9336 1.55713 14.4962 1.55713 15.1874C1.55713 15.8784 2.11911 16.4404 2.80966 16.4404C3.50086 16.4404 4.06284 15.8782 4.06284 15.1874C4.06284 14.4962 3.50086 13.9336 2.80966 13.9336Z"
      fill="currentColor"
    />
    <path
      d="M6.74202 13.6333H4.03873C3.96246 13.6333 3.89455 13.6801 3.86796 13.7509C3.8407 13.8217 3.85983 13.9021 3.91653 13.9526C4.11543 14.1313 4.26312 14.3425 4.35653 14.5801C4.38378 14.6504 4.45125 14.6966 4.52686 14.6966H6.25345C6.32862 14.6966 6.39631 14.6507 6.42378 14.581C6.51807 14.3421 6.6662 14.1307 6.86422 13.9526C6.92026 13.9021 6.93982 13.8217 6.91279 13.7509C6.88576 13.6801 6.81785 13.6333 6.74202 13.6333Z"
      fill="currentColor"
    />
    <path
      d="M9.02935 13.7507C9.0021 13.8217 9.02122 13.9019 9.07726 13.9524C9.27814 14.1331 9.42803 14.35 9.52276 14.5964C9.55023 14.6685 9.61924 14.7137 9.69353 14.7137C9.70803 14.7137 9.72298 14.712 9.73748 14.7085C9.73924 14.7082 9.77836 14.6973 9.77968 14.6968H12.961C13.0621 14.6968 13.1441 14.6148 13.1441 14.5137V13.8166C13.1441 13.7155 13.0621 13.6333 12.961 13.6333H9.20012C9.12452 13.6333 9.05661 13.6801 9.02935 13.7507Z"
      fill="currentColor"
    />
    <path
      d="M1.58086 13.6333H0.46921C0.368111 13.6333 0.286133 13.7155 0.286133 13.8166V14.5137C0.286133 14.6148 0.368111 14.6968 0.46921 14.6968H1.09273C1.16789 14.6968 1.23558 14.6509 1.26306 14.5812C1.35734 14.3419 1.50569 14.1304 1.70306 13.9529C1.7591 13.9023 1.77866 13.8219 1.75163 13.7511C1.72459 13.6801 1.65668 13.6333 1.58086 13.6333Z"
      fill="currentColor"
    />
    <path
      d="M15.6194 9.64184V11.4928C15.6194 11.5939 15.7014 11.6759 15.8025 11.6759H19.562C19.6264 11.6759 19.686 11.6423 19.7192 11.5871C19.7523 11.532 19.7537 11.4634 19.7238 11.4067L18.7352 9.55569C18.7031 9.49613 18.6409 9.45898 18.5737 9.45898H15.8025C15.7014 9.45877 15.6194 9.54074 15.6194 9.64184Z"
      fill="currentColor"
    />
    <path
      d="M13.7392 14.6968H15.1559C15.2311 14.6968 15.2986 14.6509 15.3263 14.581C15.5794 13.9375 16.1863 13.5219 16.872 13.5219C17.5581 13.5219 18.1654 13.9377 18.4201 14.5812C18.4474 14.6509 18.5148 14.6968 18.59 14.6968H19.8027C19.9038 14.6968 19.9858 14.6148 19.9858 14.5137V12.2724C19.9858 12.1713 19.9038 12.0893 19.8027 12.0893H15.4142C15.2999 12.0893 15.2074 11.9966 15.2074 11.8828V9.25286C15.2074 9.13902 15.3001 9.04671 15.4142 9.04671H18.1588C18.223 9.04671 18.2825 9.01308 18.3155 8.9577C18.3485 8.90275 18.3504 8.83396 18.3201 8.77726L17.7047 7.62649C17.6729 7.56693 17.6109 7.52979 17.5434 7.52979H13.7392C13.6384 7.52979 13.5562 7.61176 13.5562 7.71286V14.514C13.5562 14.6148 13.6384 14.6968 13.7392 14.6968Z"
      fill="currentColor"
    />
    <path
      d="M7.97103 13.9336C7.28048 13.9336 6.71851 14.4962 6.71851 15.1874C6.71851 15.8784 7.28048 16.4404 7.97103 16.4404C8.6618 16.4404 9.22356 15.8782 9.22356 15.1874C9.22356 14.4962 8.6618 13.9336 7.97103 13.9336Z"
      fill="currentColor"
    />
    <path
      d="M16.8722 13.9336C16.1814 13.9336 15.6196 14.4962 15.6196 15.1874C15.6196 15.8784 16.1816 16.4404 16.8722 16.4404C17.5636 16.4404 18.1262 15.8782 18.1262 15.1874C18.1262 14.4962 17.5636 13.9336 16.8722 13.9336Z"
      fill="currentColor"
    />
    <path
      d="M0.64178 12.2135H11.8657C12.1027 12.2135 12.2956 12.0212 12.2956 11.7838V4.70379C12.2956 4.46621 12.1027 4.27368 11.8657 4.27368H0.64178C0.404197 4.27368 0.21167 4.46621 0.21167 4.70379V11.7838C0.21167 12.0212 0.404197 12.2135 0.64178 12.2135ZM9.45848 6.57302C9.45848 6.33522 9.65101 6.14269 9.88815 6.14269C10.1262 6.14269 10.3187 6.33522 10.3187 6.57302V9.91478C10.3187 10.1521 10.1262 10.3447 9.88815 10.3447C9.65123 10.3447 9.45848 10.1521 9.45848 9.91478V6.57302ZM7.64112 6.57302C7.64112 6.33522 7.83321 6.14269 8.07079 6.14269C8.30881 6.14269 8.5009 6.33522 8.5009 6.57302V9.91478C8.5009 10.1521 8.30859 10.3447 8.07079 10.3447C7.83321 10.3447 7.64112 10.1521 7.64112 9.91478V6.57302ZM5.82354 6.57302C5.82354 6.33522 6.01607 6.14269 6.25365 6.14269C6.49123 6.14269 6.68376 6.33522 6.68376 6.57302V9.91478C6.68376 10.1521 6.49123 10.3447 6.25365 10.3447C6.01607 10.3447 5.82354 10.1521 5.82354 9.91478V6.57302ZM4.00618 6.57302C4.00618 6.33522 4.19826 6.14269 4.43629 6.14269C4.67387 6.14269 4.86596 6.33522 4.86596 6.57302V9.91478C4.86596 10.1521 4.67365 10.3447 4.43629 10.3447C4.19826 10.3447 4.00618 10.1521 4.00618 9.91478V6.57302ZM2.18837 6.57302C2.18837 6.33522 2.38068 6.14269 2.61804 6.14269C2.85541 6.14269 3.04815 6.33522 3.04815 6.57302V9.91478C3.04815 10.1521 2.85563 10.3447 2.61804 10.3447C2.38046 10.3447 2.18837 10.1521 2.18837 9.91478V6.57302Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TruckMiniIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
