<template>
  <div class="login-page full-height flex ai-c jc-c fd-c fgap2">

    <router-link :to="{ name: 'Login' }">
      <img src="@/assets/img/logo-prime.svg" alt="Prime Internacional">
    </router-link>

    <div class="offwhite-bg medium-border-radius pv-4 ph-6 text-center flex fd-c jc-c">
      <div class="span-5 p-4 text-center flex fd-c jc-c">
        <h1 class="mb-1 text-xg">Redefinição de Senha</h1>
        <p class="mb-3">Digite sua nova senha para acessar o nosso portal</p>

        <form class="flex fd-c fgap2" @submit.prevent="resetPassword">
          <Input
            label="Nova senha"
            type="password"
            name="password"
            placeholder="********"
            v-model="userData.password"
            className="input-login"
            :hint="true"
            hintMessage="Sua senha deverá conter pelo menos um caractere especial ( ex.: _()@!?$%*#&) e pelo menos um dígito."
            :error="errors.find((err) => err.path === 'password')"
          />

          <Input
            label="Repetir Nova senha"
            type="password"
            name="rePassword"
            placeholder="********"
            v-model="userData.rePassword"
            className="input-login"
            :hint="true"
            hintMessage="Repita a senha anterior"
            :error="errors.find((err) => err.path === 'rePassword')"
          />

          <button type="submit" class="btn solid primary full-width extra-big">REDEFINIR SENHA</button>
        </form>

        <router-link :to="{ name: 'Login' }" class="mt-2">Voltar para login</router-link>
      </div>
    </div>
    <cookies-policy />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Yup from 'yup';
import Input from '@/components/DataInput/Input.vue';
import CookiesPolicy from '../../components/CookiesPolicy.vue';

/* eslint-disable */
import api from '@/services/api';

export default {
  name: 'ResetPassword',
  components: {
    Input,
    CookiesPolicy
  },
  data() {
    return {
      userData: {
        password: '',
        rePassword: '',
      },
      errors: [],
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    async resetPassword() {
      this.errors = [];
      this.toggleLoading(true);
      try {
        const schema = Yup.object().shape({
          password: Yup.string()
            .required('Este campo é obrigatório')
            .min(8, 'A senha deve conter no mínimo 8 caracteres.')
            .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[_()@!?$%*#&])[A-Za-z\d_()@!?$%*#&]{8,}$/, 'A senha não segue os requisitos mínimos.'),
          rePassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'As senhas não são iguais.'),
        });

        await schema.validate(this.userData, {
          abortEarly: false,
        }).then(() => this.changePassword()).catch((errors) => {
          this.errors = errors.inner;
        });

        const response = await api.post('/credential/recovery/changePassword', {
          pin: this.$route.params.pin,
          newPassword: this.userData.password,
          newPasswordConfirm: this.userData.rePassword,
        }, { headers: { Authorization: '' } });
        if (response && response.data.code === 'SUCCESS') {
          this.$toast.success(response.data.message, {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 10000,
          });
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        } else {
          this.$toast.error(response);
        }
      } catch (err) {
        this.errors = err.inner;
      }
      this.toggleLoading(false);
    },
  },
};
</script>

<style scoped>
  .login-page {
    background: var(--primary-color);
  }

  form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1300px) {
    .login-page.columns {
      grid-template-columns: 1fr;
      background: var(--white-color);
    }
  }
</style>
