<template>
  <div class="table-overflow">
    <table
      :class="[tableClass, useFooter == false ? 'no-footer' : '', tdAuto == true ? 'td-auto' : '']">
      <thead>
      <th v-for="column in columns" :key="column.name" :class="headCentered && 'text-center'">
        {{ column.name }}
      </th>
      </thead>
      <tbody>
      <tr v-if="useFilter == true">
        <td v-for="column in columns" :key="column.key"
            :style="column.width ? 'width: '+column.width+'px' : ''">
          <input :placeholder="column.name" type="text" @input="(event) => {
              searchTerm = event.target.value;
              columnToFilter = event.target.value != '' ? column.key : ''
            }">
        </td>
      </tr>
      <tr v-for="item in filteredData" :key="item.id" @click="trAction(item)">
        <td v-for="({ name, key, ...props }) in columns" :key="name"
            :class="cellCentered && 'text-center'">
          <slot v-if="$scopedSlots[key]" :item="item" :name="key"></slot>
          <span v-if="key && !$scopedSlots[key]">
              <span v-if="props.isDate">
                {{ traverse(item, key) | moment("DD/MM/YYYY") }}
              </span>
              <span v-else-if="props.isMoney">
                {{ moneyFormat(traverse(item, key)) }}
              </span>
              <span v-else-if="key === 'identification'" class="text-bold">
                {{ traverse(item, key) }}
              </span>
              <span v-else>
                {{ traverse(item, key) }}
              </span>
            </span>
          <div v-if="key == 'actions' && actions" class="flex">
            <slot :item="item" name="actions"></slot>
          </div>
        </td>
      </tr>
      <tr v-if="filteredData == undefined || filteredData.length <= 0">
        <td :colspan="columns.length">
          <h4 class="text-center text-sm md-pd">
            Nenhum Processo de Importação foi encontrado de acordo com o filtro atual.
          </h4>
        </td>
      </tr>
      </tbody>
      <tfoot v-if="useFooter == true">
      <th :colspan="columns.length">
        <slot name="footer"></slot>
      </th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Table',
  computed: {
    filteredData() {
      return this.data.filter((item) => String(this.traverse(item, this.columnToFilter)).match(new RegExp(this.searchTerm, 'gi')));
    },
  },
  data() {
    return {
      searchTerm: '',
      columnToFilter: '',
    };
  },
  methods: {
    traverse(obj, keys) {
      return keys.split('.').reduce((cur, key) => (cur[key] ? cur[key] : ''), obj);
    },
    moneyFormat(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    trAction: {
      type: Function,
      default: () => {
      },
    },
    actions: {
      type: Boolean,
      default: false,
    },
    tableClass: {
      type: String,
      default: '',
    },
    headCentered: {
      type: Boolean,
      default: false,
    },
    cellCentered: {
      type: Boolean,
      default: false,
    },
    useFooter: {
      type: Boolean,
      default: false,
    },
    useFilter: {
      type: Boolean,
      default: false,
    },
    tdAuto: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  font-size: 0.8rem;
}

tr td {
  transition: var(--all-transition);
}

tr:hover td {
  background-color: var(--light-bg-color);
}
</style>
