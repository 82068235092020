<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 61 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_626_675)">
      <path
        d="M59.6226 11.9741H57.8057C56.8537 11.9741 56.0768 12.6711 56.0597 13.5333H54.6629C54.1719 13.5333 53.7735 13.8957 53.7735 14.3423C53.7735 14.7894 54.1719 15.1514 54.6629 15.1514H56.0916C57.0436 15.1514 57.8205 14.4548 57.8381 13.5923H59.1811V41.6901C59.1811 41.7065 59.1663 41.72 59.1482 41.72H2.05179C2.03372 41.72 2.0189 41.7065 2.0189 41.6901V13.5923H3.35215C3.36975 14.4548 4.14617 15.1514 5.09863 15.1514H50.4246C50.9157 15.1514 51.3141 14.7894 51.3141 14.3423C51.3141 13.8957 50.9157 13.5333 50.4246 13.5333H5.13059C5.11345 12.6711 4.33657 11.9741 3.38411 11.9741H1.57741C0.839908 11.9741 0.23999 12.5198 0.23999 13.1907V41.6901C0.23999 42.5986 1.05301 43.3382 2.05179 43.3382H59.1482C60.147 43.3382 60.96 42.5986 60.96 41.6901V13.1907C60.96 12.5198 60.3601 11.9741 59.6226 11.9741V11.9741Z"
        fill="currentColor"
      />
      <path
        d="M5.34879 17.8513C4.85774 17.8513 4.45934 18.2137 4.45934 18.6604V38.5757C4.45934 39.0224 4.85774 39.3848 5.34879 39.3848H56.1148C56.6058 39.3848 57.0042 39.0224 57.0042 38.5757V18.6604C57.0042 18.2137 56.6058 17.8513 56.1148 17.8513H5.34879ZM45.9949 37.7666H43.1589V34.1435C43.1589 33.6964 42.7605 33.3344 42.2694 33.3344C41.7784 33.3344 41.38 33.6964 41.38 34.1435V37.7666H38.5439V19.4695H41.38V30.174C41.38 30.6207 41.7784 30.9831 42.2694 30.9831C42.7605 30.9831 43.1589 30.6207 43.1589 30.174V19.4695H45.9949V37.7666ZM47.7738 19.4695H50.6104V37.7666H47.7738V19.4695ZM20.0836 19.4695H22.9197V37.7666H20.0836V19.4695ZM18.3047 37.7666H15.4686V19.4695H18.3047V37.7666ZM24.6986 19.4695H27.5346V37.7666H24.6986V19.4695ZM29.3135 19.4695H32.15V37.7666H29.3135V19.4695ZM33.929 19.4695H36.765V37.7666H33.929V19.4695ZM6.23824 19.4695H9.0743V20.8432C9.0743 21.2899 9.4727 21.6523 9.96375 21.6523C10.4553 21.6523 10.8532 21.2899 10.8532 20.8432V19.4695H13.6897V37.7666H10.8532V24.9496C10.8532 24.503 10.4553 24.1406 9.96375 24.1406C9.4727 24.1406 9.0743 24.503 9.0743 24.9496V37.7666H6.23824V19.4695ZM55.2253 37.7666H52.3893V19.4695H55.2253V37.7666Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_626_675">
        <rect
          width="60.72"
          height="55.2324"
          fill="currentColor"
          transform="translate(0.23999)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ContainerIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
