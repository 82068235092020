<template>
  <div>
    <PageTitle :pageTitle="pageTitle" :breadcrumbName="breadcrumbName" class="mb-2"/>

    <div class="card columns gap2 mb-1">
      <div class="span-5">
        <h5 class="mb-1 flex ai-c jc-sb text-uppercase text-medium">
          RELATÓRIO GERAL DOS PROCESSOS DE IMPORTAÇÃO
        </h5>
        <p class="text-light text-sm">Quantidade de Processos de Importação do Filtro:
          <strong>{{ processes.length }} processos</strong></p>
      </div>

      <div class="span-7 warning-bg lt-pd">
        <h5>
          <span class="text-sm text-right">
            <span class="text-bold">
            Para que os Procesos de Importação sejam exibidos
            </span>
            <br/>
            Por Etapa: Digite no campo Pesquisa (Invoice, Ref Pedido, BL/AWB/CRT, Exportador, Fabricante e/ou Nº DI)
            Por Período: Selecione um campo de Data e um Período Inicial e Final.
          </span>
        </h5>
      </div>

      <div class="span-12 columns gap2">
        <div class="span-2">
          <Select title="Tipo de Filtro" v-model="tipoFiltro" :options="tiposFiltro"/>
        </div>
        <div class="span-4" v-if="tipoFiltro === 'filtroGeral'">
          <StyledInput class="rounded" name="generalSearch" type="text"
                       placeholder="Digite a Palavra Chave" v-model="generalSearchInput"/>
        </div>
        <div class="span-3" v-if="tipoFiltro === 'porPeriodo'">
          <Select title="Tipo de Data" v-model="filtroCampo" :options="listOfDatesFields"
                  :useIcon="true">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div class="span-2 date-picker" v-if="tipoFiltro === 'porPeriodo'">
          <Select title="Data Inicial" v-model="filtroDataInicial" :useIcon="true" :useDate="true">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div class="span-2 date-picker" v-if="tipoFiltro === 'porPeriodo'">
          <Select title="Data Final" v-model="filtroDataFinal" :useIcon="true" :useDate="true">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div class="span-3">
          <button class="btn medium solid primary" v-if="tipoFiltro === 'filtroGeral'"
                  @click="submitSearchGeneralFilter">GERAR
          </button>
          <button class="btn medium solid primary" v-else @click="submitSearchDataFilter">GERAR
          </button>
          <download-excel class="btn solid success ml-1 btn-exportar" :data="processes"
                          :fields="fieldsExcel" name="relatorio-processos-importacao.xls"
                          v-if="processes.length > 0">GERAR EXCEL
          </download-excel>
        </div>
      </div>
    </div>
    <div class="card columns gap2 mb-2">
      <div class="span-12">
        <Table :columns="columns" :data="processes" :trAction="openProcess" tableClass="vgt-table center text-sm text-table text-medium sm-pd">
          <template #identification="{ item }">
            {{ item.identification | typeOfProcess }}{{ String(item.identification).padStart(5, '0') }}
          </template>
          <template #customer.name="{ item }">
            <span :class="{ blured: presentationMode }">
              {{ item.customer.name }}
            </span>
          </template>
          <template #exporter.name="{ item }">
            <span :class="{ blured: presentationMode }">
              {{ item.exporter.name }}
            </span>
          </template>
        </Table>
      </div>
    </div>
    <ModalProcess
      :handler="modalProcessOpen"
      @request-close="() => {
        reminderOpen = false
        modalProcessOpen = false
      }"
      :data="modalData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Table from '@/components/Table.vue';
import PageTitle from '@/components/PageTitle.vue';
import StyledInput from '@/components/DataInput/StyledInput.vue';
import Select from '@/components/DataInput/Select.vue';

import * as labelsOfImportProcess from '@/utils/DateLabelsUtil';
import ProcessesReportColumnsExcel from '@/utils/ProcessesReportColumnsExcel';
import ModalProcess from '@/components/ModalProcess.vue';

import {
  // MapBoxIcon,
  CalendarIcon,
} from '@/components/Icons';

export default {
  components: {
    Table,
    PageTitle,
    StyledInput,
    Select,
    CalendarIcon,
    ModalProcess,
  },
  computed: {
    ...mapState(['presentationMode']),
    ...mapState('process', ['processes']),
    listOfDatesFields() {
      const data = new Array(0);
      if (this.labels !== undefined) {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.labels) {
          if (this.labels[prop].type === 'date') {
            data.push(this.labels[prop]);
          }
        }
      }
      return data;
    },
  },
  data() {
    return {
      modalProcessOpen: false,
      filtroEtapa: { label: 'Produção', value: 7 },
      generalSearchInput: '',
      filtroCampo: '',
      teste: '',
      filtroDataInicial: '',
      filtroDataFinal: '',
      tipoFiltro: 'filtroGeral',
      modalData: {},
      tiposFiltro: [
        {
          label: 'Palava Chave',
          value: 'filtroGeral',
        },
        {
          label: 'Por Período',
          value: 'porPeriodo',
        },
      ],
      labels: {
        id: {
          label: 'ID',
        },
        ...labelsOfImportProcess.labels[0],
      },
      columns: [
        {
          name: 'Ref',
          key: 'identification',
          width: 100,
          isDate: false,
        },
        {
          name: 'Etapa',
          key: 'step.description',
          isDate: false,
        },
        {
          name: 'Empresa',
          key: 'customer.name',
          isDate: false,
        },
        {
          name: 'Exportador',
          key: 'exporter.name',
          isDate: false,
        },
        {
          name: 'Ref Pedido',
          key: 'customerRef',
          isDate: false,
        },
        {
          name: 'Outras referências',
          key: 'anotherRef',
          isDate: false,
        },
        {
          name: 'Status',
          key: 'status',
          isDate: false,
        },
        {
          name: 'Invoice',
          key: 'invoice',
          isDate: false,
        },
        {
          name: 'Incoterm',
          key: 'incoterm',
        },
        {
          name: 'Forwarder',
          key: 'freightForwarder.name',
        },
        {
          name: 'CNTR',
          key: 'typeOfBoarding',
        },
        {
          name: 'Prontidão Prev',
          key: 'datesEstimatedGoodsReadinesDate',
          isDate: true,
        },
        {
          name: 'ETD',
          key: 'datesETD',
          isDate: true,
        },
        {
          name: 'ETA',
          key: 'datesETA',
          isDate: true,
        },
        {
          name: 'Entrega Prev',
          key: 'datesEstimatedCustomerDeliverDate',
          isDate: true,
        },
        {
          name: 'Embarque',
          key: 'datesDepartureDate',
          isDate: true,
        },
        {
          name: 'Chegada',
          key: 'datesArrivalDate',
          isDate: true,
        },
        {
          name: 'Nº DI',
          key: 'diNumber',
        },
        {
          name: 'Registro',
          key: 'diRegistryDate',
          isDate: true,
        },
        {
          name: 'Valor CIF',
          key: 'cifValue',
          isMoney: true,
        },
      ],
      fieldsExcel: ProcessesReportColumnsExcel,
    };
  },
  filters: {
    typeOfProcess(value) {
      switch (value) {
        case 'CONTA_E_ORDEM':
          return 'PRI';

        case 'ENCOMENDA':
          return 'E';

        case 'ACESSORIA':
          return 'A';

        default:
          return 'PRI';
      }
    },
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('process', ['updateProcesses', 'getProcessDocuments']),
    ...mapActions(['toggleLoading']),
    async submitSearchGeneralFilter() {
      if (this.generalSearchInput !== '') {
        this.toggleLoading(true);
        const route = `/customers/import/search?concluidos=true&q=${this.generalSearchInput}`;
        const response = await api.get(route);
        const { data } = response.data;
        this.updateProcesses(data);
        this.toggleLoading(false);
      }
    },
    async submitSearchDataFilter() {
      this.generalSearchInput = '';
      if (this.filtroCampo !== '' && this.filtroDataInicial !== '' && this.filtroDataFinal !== '') {
        this.toggleLoading(true);
        const dataInicial = `${moment(this.filtroDataInicial, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00`;
        const dataFinal = `${moment(this.filtroDataFinal, 'DD/MM/YYYY').format('Y-MM-DDT')}23:00:00`;
        const params = `concluido=true&field=${this.filtroCampo}&from=${dataInicial}&to=${dataFinal}`;
        const route = `/customers/import/report/general?${params}`;
        const response = await api.get(route);
        this.updateProcesses(response.data.data);
        this.toggleLoading(false);
      }
    },
    async openProcess(process) {
      this.modalData = process;
      this.modalData.documents = await this.getProcessDocuments(process.id);
      this.modalProcessOpen = true;
    },
  },
  mounted() {
    this.updateProcesses([]);
  },
  destroyed() {
    this.updateProcesses([]);
  },
};
</script>

<style scoped>
.div-buttons {
  margin-top: 4px;
}

.date-picker {
  min-width: 310px;
}
</style>
