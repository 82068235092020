<template>
  <div>
    <div class="flex ai-c">
      <h2 class="mr-2">{{ pageTitle }}</h2>
      <div class="flex ai-c text-sm" v-if="breadcrumbName">
        <router-link :to="{ name: 'Home' }" class="text-light text-thin">
          Portal dos Clientes
        </router-link>

        <span class="text-light mh-1">/</span>

        <p class="text-primary">{{ breadcrumbName }}</p>
      </div>
    </div>

    <p class="text-light" style="line-height: 2" v-if="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
