<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 13.125C12.5 13.4703 12.2203 13.75 11.875 13.75H8.125C7.77969 13.75 7.5 13.4703 7.5 13.125V11.25H0V16.875C0 17.875 0.875 18.75 1.875 18.75H18.125C19.125 18.75 20 17.875 20 16.875V11.25H12.5V13.125ZM18.125 5H15V3.125C15 2.125 14.125 1.25 13.125 1.25H6.875C5.875 1.25 5 2.125 5 3.125V5H1.875C0.875 5 0 5.875 0 6.875V10H20V6.875C20 5.875 19.125 5 18.125 5ZM12.5 5H7.5V3.75H12.5V5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'BriefcaseIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
