<template>
  <div class="offwhite-bg pv-2 ph-1 medium-border-radius">
    <ul class="updates-list flex fd-c fgap2">
      <li class="relative" v-for="update in data" :key="update.id">
        <div class="update-complete text-primary offwhite-bg" v-if="update.concluido === true">
          <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 5.5L6.5 10L14.5 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="update-incomplete text-primary offwhite-bg" v-else>
          <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H8" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </div>
        <p class="text-secondary text-font" :class="{ 'text-opacity': update.previsao && update.opacidade }" >{{update.titulo}}</p>
        <p class="text-primary text-bold" :class="{ 'text-opacity': update.previsao && update.opacidade }" >{{update.descricao}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UpdatesList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
  .text-font{
    font-size: 0.9rem;
  }

  .updates-list {
    position: relative;
  }

  .updates-list li {
    padding-left: 60px;
  }
  .updates-list li p {
    line-height: 1.2em;
  }

  .updates-list li:last-child{
    margin-bottom: 15px;
  }

  .updates-list::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    border: 2px dashed var(--accent-color);
    transform: translate3d(-50%, 0, 0);
  }

  .updates-list::after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    /* background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23DBAF57' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center center / cover no-repeat; */
    background: var(--accent-color);
    /* top: calc(100% - 10px); */
    bottom: -10px;
    left: 30px;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
  }

  .update-complete,
  .update-incomplete {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--accent-color);
    border-radius: var(--medium-border-radius);
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0px;
    left: 30px;
    transform: translate3d(-50%, 0, 0);
  }

  .update-incomplete {
    color: #7B91B0;
    border: 3px solid #7B91B0;
  }

  .text-opacity {
    opacity: 0.4;
  }
</style>
