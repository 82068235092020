var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card flex fd-c fgap2"},[_c('div',{staticClass:"flex ai-c fw-w jc-sb fgap2"},[_vm._m(0),_c('div',{staticClass:"flex ai-c fw-w fgap2"},[_c('div',[_c('Select',{attrs:{"title":"País de Origem","multiple":true,"options":_vm.originsFilterValues},model:{value:(_vm.filterData.origin),callback:function ($$v) {_vm.$set(_vm.filterData, "origin", $$v)},expression:"filterData.origin"}})],1),_c('div',[_c('Select',{attrs:{"title":"Local de Embarque","multiple":true,"options":_vm.placeOfLoadingsFilterValues},model:{value:(_vm.filterData.boarding),callback:function ($$v) {_vm.$set(_vm.filterData, "boarding", $$v)},expression:"filterData.boarding"}})],1),_c('div',[_c('Select',{attrs:{"title":"Modal","multiple":true,"options":[
            {
              label: 'Aéreo',
              value: 'AEREA'
            },
            {
              label: 'Marítimo',
              value: 'MARITIMA'
            },
            {
              label: 'Rodovia',
              value: 'RODOVIARIA'
            },
          ]},model:{value:(_vm.filterData.modal),callback:function ($$v) {_vm.$set(_vm.filterData, "modal", $$v)},expression:"filterData.modal"}})],1),_c('div',[_c('Select',{attrs:{"title":"Data Inicial","useIcon":true,"useDate":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CalendarIcon')]},proxy:true}]),model:{value:(_vm.filterData.start_date),callback:function ($$v) {_vm.$set(_vm.filterData, "start_date", $$v)},expression:"filterData.start_date"}})],1),_c('div',[_c('Select',{attrs:{"title":"Data Final","useIcon":true,"useDate":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CalendarIcon')]},proxy:true}]),model:{value:(_vm.filterData.end_date),callback:function ($$v) {_vm.$set(_vm.filterData, "end_date", $$v)},expression:"filterData.end_date"}})],1),_c('div',[_c('button',{staticClass:"btn solid primary rounded",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.getAverages.apply(null, arguments)}}},[_vm._v("Filtrar")])])])]),_c('div',{staticClass:"card light mb-2"},[_c('div',{staticClass:"flex ai-c jc-sb mb-2"},[_vm._m(1),_c('div',{staticClass:"flex ai-c fgap2"},[_c('h4',{staticClass:"text-medium"},[_vm._v("TRANSIT TIME MÉDIO "),_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.transitTimeMedium))]),_vm._v(" DIAS")])])]),_c('div',{staticClass:"table-overflow"},[_c('table',{staticClass:"primary spaced"},[_vm._m(2),_c('tbody',[((_vm.averages.length === 0))?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Nenhum processo filtrado.")])]):_vm._l((_vm.averages),function(average,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(average.countryOrigin))]),_c('td',[_vm._v(_vm._s(average.boardingPlace))]),_c('td',[_vm._v(_vm._s(average.wayOfTransport))]),_c('td',[_vm._v(_vm._s(average.avaregeOfDays))])])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"text-uppercase text-medium"},[_vm._v(" TRANSIT TIME ")]),_c('p',{staticClass:"text-light"},[_vm._v(" Selecione um perído no filtro de datas para buscar os resultados. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"text-uppercase text-medium"},[_vm._v(" TOTAL DE PROCESSOS DE IMPORTAÇÃO ")]),_c('p',{staticClass:"text-light"},[_vm._v(" Quantidade total de processos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("País de Origem")]),_c('th',[_vm._v("Local de Embarque")]),_c('th',[_vm._v("Modal")]),_c('th',[_vm._v("Transit Time")])])])
}]

export { render, staticRenderFns }