<template>
  <div class="card flex fd-c fgap2">

    <div class="flex ai-c fw-w jc-sb fgap2">
      <div>
        <h4 class="text-uppercase text-medium">
          TRANSIT TIME
        </h4>
        <p class="text-light">
          Selecione um perído no filtro de datas para buscar os resultados.
        </p>
      </div>
      <div class="flex ai-c fw-w fgap2">
        <div>
          <Select
            title="País de Origem"
            :multiple="true"
            v-model="filterData.origin"
            :options="originsFilterValues"
          >
          </Select>
        </div>
        <div>
          <Select
            title="Local de Embarque"
            :multiple="true"
            v-model="filterData.boarding"
            :options="placeOfLoadingsFilterValues"
          >
          </Select>
        </div>
        <div>
          <Select
            title="Modal"
            :multiple="true"
            v-model="filterData.modal"
            :options="[
              {
                label: 'Aéreo',
                value: 'AEREA'
              },
              {
                label: 'Marítimo',
                value: 'MARITIMA'
              },
              {
                label: 'Rodovia',
                value: 'RODOVIARIA'
              },
            ]"
          >
          </Select>
        </div>
        <div>
          <Select
            title="Data Inicial"
            :useIcon="true"
            :useDate="true"
            v-model="filterData.start_date"
          >
            <template #icon>
              <CalendarIcon />
            </template>
          </Select>
        </div>
        <div>
          <Select
            title="Data Final"
            :useIcon="true"
            :useDate="true"
            v-model="filterData.end_date"
          >
            <template #icon>
              <CalendarIcon />
            </template>
          </Select>
        </div>
        <div>
          <button class="btn solid primary rounded" type="button" @click.prevent="getAverages">Filtrar</button>
        </div>
      </div>
    </div>

    <div class="card light mb-2">
      <div class="flex ai-c jc-sb mb-2">
        <div>
          <h4 class="text-uppercase text-medium">
            TOTAL DE PROCESSOS DE IMPORTAÇÃO
          </h4>
          <p class="text-light">
            Quantidade total de processos
          </p>
        </div>

        <div class="flex ai-c fgap2">
          <h4 class="text-medium">TRANSIT TIME MÉDIO <span class="text-lg">{{ transitTimeMedium }}</span> DIAS</h4>
        </div>
      </div>

      <div class="table-overflow">
        <table class="primary spaced">
          <thead>
          <tr>
            <th>País de Origem</th>
            <th>Local de Embarque</th>
            <th>Modal</th>
            <th>Transit Time</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="(averages.length === 0)">
            <td class="text-center" colspan="4">Nenhum processo filtrado.</td>
          </tr>
          <tr v-else v-for="(average, key) in averages" :key="key">
            <td>{{ average.countryOrigin }}</td>
            <td>{{ average.boardingPlace }}</td>
            <td>{{ average.wayOfTransport }}</td>
            <td>{{ average.avaregeOfDays }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="card primary">
      <div class="flex ai-c mb-2">
        <div>
          <h4 class="text-uppercase text-medium">
            TOTAL DE PROCESSOS DE IMPORTAÇÃO
          </h4>
          <p class="text-light">
            Quantidade total de processos por etapa
          </p>
        </div>
      </div>

      <div class="chart">
        <LineChart />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import api from '@/services/api';

// import LineChart from '@/components/LineChart.vue';
import Select from '@/components/DataInput/Select.vue';

import { CalendarIcon } from '@/components/Icons';

export default {
  name: 'HomeTransitTimeTab',
  components: {
    CalendarIcon,
    // LineChart,
    Select,
  },
  data() {
    return {
      averages: [],
      transitTimeMedium: 0,
      originsFilterValues: [],
      placeOfLoadingsFilterValues: [],
      filterData: {
        boarding: null,
        modal: null,
        origin: null,
        start_date: null,
        end_date: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions(['toggleLoading']),
    hasDates() {
      if (moment(this.filterData.start_date, 'DD/MM/YYYY').isValid() && moment(this.filterData.end_date, 'DD/MM/YYYY').isValid()) {
        if (moment(this.filterData.end_date, 'DD/MM/YYYY').isBefore(moment(this.filterData.start_date, 'DD/MM/YYYY'))) {
          return 'invalid';
        }
      }
      if ((this.filterData.start_date === null || this.filterData.start_date === '') && (this.filterData.end_date === null || this.filterData.end_date === '')) {
        return 'no-data';
      }
      return true;
    },
    async getAverages() {
      if (this.hasDates() === 'invalid') {
        this.$toast.error('A data final não pode ser menor que a data inicial');
        return;
      }
      if (this.hasDates() === 'no-data') {
        this.$toast.error('Preencha as datas para filtrar.');
        return;
      }
      if (
        (this.filterData.start_date === null || this.filterData.start_date === '') && (this.filterData.end_date === null && this.filterData.end_date === '')
      ) {
        this.averages = [];
      } else {
        this.toggleLoading(true);

        // Montagem dos filtros
        const origins = [];
        const modais = [];
        const placesOfLoading = [];
        const data = {};

        if (this.filterData.origin !== null && this.filterData.origin.length > 0) {
          this.filterData.origin.forEach((origin) => {
            origins.push(`'${origin}'`);
          });
        }

        if (this.filterData.modal !== null && this.filterData.modal.length > 0) {
          this.filterData.modal.forEach((modal) => {
            modais.push(`'${modal}'`);
          });
        }

        if (this.filterData.boarding !== null && this.filterData.boarding.length > 0) {
          this.filterData.boarding.forEach((boarding) => {
            placesOfLoading.push(`'${boarding}'`);
          });
        }

        if (this.filterData.start_date !== null && this.filterData.start_date !== '') {
          data.startDate = moment(this.filterData.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        if (this.filterData.end_date !== null && this.filterData.end_date !== '') {
          data.endDate = moment(this.filterData.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        data.origins = origins.join(',');
        data.modais = modais.join(',');
        data.placesOfLoading = placesOfLoading.join(',');
        data.companiesIds = this.user
          .companies
          .filter((a) => a.customer)
          .map((a) => a.id)
          .join();

        // Finalização da montagem dos filtros

        try {
          const response = await api.get('/api/public/customer/analytics/averageByOriginLeadTimeIndicator', { params: data });

          this.averages = response.data.data;

          if (this.averages.length > 0) {
            let total = 0;
            let count = 0;

            this.averages.forEach((item) => {
              total += item.avaregeOfDays;
              // eslint-disable-next-line no-plusplus
              count++;
            });

            // eslint-disable-next-line radix
            this.transitTimeMedium = parseInt(total / count);
          } else {
            this.transitTimeMedium = 0;
          }

          this.toggleLoading(false);
        } catch (e) {
          this.toggleLoading(false);
        }
      }
    },
    async getAvaregesFilterValues() {
      this.toggleLoading(true);
      try {
        const data = {};

        data.companiesIds = this.user
          .companies
          .filter((a) => a.customer)
          .map((a) => a.id)
          .join();

        const response = await api.get('/api/public/customer/analytics/averageByOriginLeadTimeIndicatorFilters', { params: data });

        if (response.data.data) {
          const originsValues = response.data.data.origins;
          const placeOfLoadingsValues = response.data.data.placesOfLoading;

          if (originsValues.length > 0) {
            originsValues.forEach((origin) => {
              this.originsFilterValues.push({
                label: origin,
                value: origin,
              });
            });
          }

          if (placeOfLoadingsValues.length > 0) {
            placeOfLoadingsValues.forEach((place) => {
              this.placeOfLoadingsFilterValues.push({
                label: place,
                value: place,
              });
            });
          }
        }

        this.toggleLoading(false);
      } catch (e) {
        this.toggleLoading(false);
      }
    },
  },
  mounted() {
    // this.getAverages();
    this.getAvaregesFilterValues();
  },
};
</script>
